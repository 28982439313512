import React, { useState, useEffect } from 'react';

import { Helmet } from 'react-helmet';
import BannersPages from '../../components/Sliders/pages';
import Agendamentos from '../../components/Agenda';

import bgMobile from '../../assets/images/bg_agenda_mobile.jpg';
import bg from '../../assets/images/bg_agenda.png';
import api from '../../services/api';
import Loading from '../../components/Loading';
import Instagram from '../../components/Boxes/instagram';

function Agenda() {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .get(`eventos?dia_gte=${new Date().toISOString()}_sort=id:ASC`)
      .then(({ data }) => {
        setEvents(data);
        setLoading(false);
      });
  }, []);

  return (
    <main>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Helmet>
            <title>Agenda - {process.env.REACT_APP_TITLE}</title>
          </Helmet>
          <BannersPages
            {...{
              bgMobile,
              bg,
              text: 'Nossa Agenda',
              title: 'Agenda',
              subtitle: ''
            }}
          />
          <Agendamentos {...{ events }} />
        </>
      )}
    </main>
  );
}

export default Agenda;
