import { Picture } from 'react-responsive-picture';
import CenterTitlePage from '../Titles/centerPages';

function BannersPageLive({ bgMobile, bg, text, title, subtitle }) {
  const arrayPages = ['agenda', 'blog', 'podcasts', 'contato', 'live'];
  const page = document.location.pathname.replace('/', '');
  const handleDragStart = e => e.preventDefault();

  return (
    <div className={`highlight_pages ${arrayPages.includes(page) ? page : ''}`}>
      <div className='bannersPages'>
        <div className='slide live'>
          <Picture
            className='image'
            onDragStart={handleDragStart}
            sources={[
              {
                srcSet: bgMobile,
                media: `(max-width: ${process.env.REACT_APP_MOBILE})`
              },
              {
                srcSet: bg
              }
              // {
              //   srcSet: "path-to-desktop-image.webp",
              //   type: "image/webp"
              // }
            ]}
          />
          <CenterTitlePage
            {...{
              text,
              title,
              subtitle
            }}
          />
          <div className='social internal'>
            <a
              href={process.env.REACT_APP_FACEBOOK}
              rel='noreferrer'
              target='_blank'
            >
              Fb
            </a>
            <a
              href={process.env.REACT_APP_INSTAGRAM}
              rel='noreferrer'
              target='_blank'
            >
              In
            </a>
            <a
              href={process.env.REACT_APP_YOUTUBE}
              rel='noreferrer'
              target='_blank'
            >
              Ytb
            </a>
          </div>
          <div
            className={`breadcrumb ${
              arrayPages.includes(page) ? 'internal' : ''
            }`}
            data-aos='fade-up'
            data-aos-offset='200'
            data-aos-delay='50'
            data-aos-duration='700'
            data-aos-easing='ease-in-out'
          >
            <a href='/'>Home</a>
            <a href='/'>{subtitle || title}</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BannersPageLive;
