import React from 'react';
import ScrollArea from 'react-scrollbar';
import { parseISO, getMonth, getYear } from 'date-fns';

import check from '../../assets/images/agenda_check.png';
import ReplaceImageUrlService from '../../services/ReplaceImageUrlService';

function Agendamentos({ events }) {
  const formatDate = ({ parsedDate, options }) =>
    Intl.DateTimeFormat('pt-BR', options).format(parsedDate);

  const organizeEvents = () => {
    if (!events || events.length < 1) return [];

    const months = [];

    let lastEventMonth = getMonth(parseISO(events[0].dia));
    let lastEventYear = getMonth(parseISO(events[0].dia));

    events.forEach(event => {
      const thisEventMonth = getMonth(parseISO(event.dia));
      const thisEventYear = getYear(parseISO(event.dia));

      if (
        thisEventMonth === lastEventMonth &&
        thisEventYear === lastEventYear
      ) {
        months[months.length - 1].events.push(event);
      } else {
        const parsedDate = parseISO(event.dia);

        months.push({
          month: `${formatDate({
            parsedDate,
            options: { month: 'long' }
          })} - ${formatDate({ parsedDate, options: { year: 'numeric' } })}`,
          events: [event]
        });

        lastEventMonth = thisEventMonth;
        lastEventYear = thisEventYear;
      }
    });

    return months;
  };

  return (
    <section className='internalPage agenda'>
      <div
        className='container'
        data-aos='fade-up'
        data-aos-offset='200'
        data-aos-delay='50'
        data-aos-duration='700'
        data-aos-easing='ease-in-out'
      >
        <div className='scroll'>
          <ScrollArea
            speed={0.8}
            // className='scroll'
            contentClassName='content'
            horizontal={false}
          >
            {events &&
              organizeEvents().map((period, index) => (
                <div key={`mes-${index}`} className='agendaMonth'>
                  <strong>{period.month}</strong>
                  <table>
                    <tbody>
                      {period.events.map((event, periodIndex) => (
                        <tr key={`period-${periodIndex}`}>
                          <td>
                            <span>
                              {formatDate({
                                parsedDate: parseISO(event.dia),
                                options: { day: 'numeric', month: 'numeric' }
                              })}
                            </span>
                          </td>
                          <td>
                            {/* link: event.link */}
                            <img src={ReplaceImageUrlService(check)} className='check' alt='Check' />
                          </td>
                          <td>
                            <span>{event.titulo}</span>
                          </td>
                          <td className='descricao'>
                            {event.descricao}
                            <div className='mobile'>{event.local}</div>
                          </td>
                          <td className='local'>LOCAL: {event.local}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ))}
          </ScrollArea>
        </div>

        {/* <SimpleBar className='scroll' autoHide={false}> */}
        {/*  {events && */}
        {/*    organizeEvents().map((period, index) => ( */}
        {/*      <div key={index} className='agendaMonth'> */}
        {/*        <strong>{period.month}</strong> */}
        {/*        <table> */}
        {/*          <tbody> */}
        {/*            {period.events.map((event, periodIndex) => ( */}
        {/*              <tr key={periodIndex}> */}
        {/*                <td> */}
        {/*                  <span> */}
        {/*                    {formatDate({ */}
        {/*                      parsedDate: parseISO(event.dia), */}
        {/*                      options: { day: 'numeric', month: 'numeric' } */}
        {/*                    })} */}
        {/*                  </span> */}
        {/*                </td> */}
        {/*                <td> */}
        {/*                  /!* link: event.link *!/ */}
        {/*                  <img src={check} className='check' alt='Check' /> */}
        {/*                </td> */}
        {/*                <td> */}
        {/*                  <span>{event.titulo}</span> */}
        {/*                </td> */}
        {/*                <td>{event.descricao}</td> */}
        {/*              </tr> */}
        {/*            ))} */}
        {/*          </tbody> */}
        {/*        </table> */}
        {/*      </div> */}
        {/*    ))} */}
        {/* </SimpleBar> */}
      </div>
    </section>
  );
}

export default Agendamentos;
