import icone from '../../assets/images/icone_servicos01.png';
import ReplaceImageUrlService from '../../services/ReplaceImageUrlService';

function About({ image, title, description }) {
  return (
    <div
      className='aboutBox'
      data-aos='fade-up'
      data-aos-offset='200'
      data-aos-delay='50'
      data-aos-duration='700'
      data-aos-easing='ease-in-out'
    >
      <img src={ReplaceImageUrlService(image || icone)} alt={title} title={title} />
      <strong>{title}</strong>
      <p>{description}</p>
    </div>
  );
}

export default About;
