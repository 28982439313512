import { toast } from 'react-toastify';

const execute = error => {
  let errorMessage = '';

  try {
    errorMessage = error.response.data.message;
  } catch (p) {
    errorMessage = process.env.REACT_APP_FAILED_REQUEST_MESSAGE;
  }

  toast.error(errorMessage);
};

export default execute;
