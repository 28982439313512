import Feed from 'react-instagram-authless-feed';
import InstagramTitle from '../Titles/instagram';

// import insta from '../../assets/images/instagram_image.jpg';
import insta01 from '../../assets/images/insta01.jpg';
import insta02 from '../../assets/images/insta02.jpg';
import insta03 from '../../assets/images/insta03.jpg';

function Instagram() {
  return (
    <section className='content instagram'>
      <InstagramTitle
        {...{
          title: 'Instagram',
          subtitle: 'Acompanhe nosso Instagram'
        }}
      />
      <div className='instagramBoxes'>
        {/* <figure className='instagramImages'> */}
        {/*  <img src={insta01} alt='Instagram' /> */}
        {/* </figure> */}
        {/* <figure className='instagramImages'> */}
        {/*  <img src={insta02} alt='Instagram' /> */}
        {/* </figure> */}
        {/* <figure className='instagramImages'> */}
        {/*  <img src={insta03} alt='Instagram' /> */}
        {/* </figure> */}
        <Feed
          userName='tofainstituto'
          className='instagramImagesBoxes'
          classNameLoading='Loading'
          limit='4'
        />
      </div>
    </section>
  );
}

export default Instagram;
