import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import BannersPages from '../../components/Sliders/pages';

import bgMobile from '../../assets/images/bg_blog_mobile.jpg';
import bg from '../../assets/images/bg_blog_home.jpg';
import Podcasts from '../../components/Boxes/podcasts';
import Loading from '../../components/Loading';

function Blog() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <main>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Helmet>
            <title>Podcasts - {process.env.REACT_APP_TITLE}</title>
          </Helmet>
          <BannersPages
            {...{
              bgMobile,
              bg,
              text: 'Nosso Blog',
              title: 'Blog',
              subtitle: ''
            }}
          />
          <section className='internalPageBlog'>
            <Podcasts />
          </section>
        </>
      )}
    </main>
  );
}

export default Blog;
