import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../../assets/images/lotties/7908-loading.json';

function Loading() {
  return (
    <div className='loading'>
      <Lottie
        speed={0.36}
        options={{
          loop: true,
          autoplay: true,
          animationData,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        }}
        height={220}
        width={220}
      />
    </div>
  );
}

export default Loading;
