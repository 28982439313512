function PartnersTitle({ title, subtitle }) {
  return (
    <div
      className='partnersTitle'
      data-aos='fade-up'
      data-aos-offset='200'
      data-aos-delay='50'
      data-aos-duration='700'
      data-aos-easing='ease-in-out'
    >
      <span>{title}</span>
      <p>{subtitle}</p>
    </div>
  );
}

export default PartnersTitle;
