import React, { useEffect, useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';

import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import { mask, unMask } from 'remask';
import api from '../../services/api';

import Loading from '../../components/Loading';
import BannersPages from '../../components/Sliders/pages';
import CenterTitle from '../../components/Titles/center';
import BoxWeAre from '../../components/Boxes/weAre';
import About from '../../components/Boxes/about';
// import Instagram from '../../components/Boxes/instagram';
import StrokeTitleCenter from '../../components/Titles/strokeCenter';
import ArticlesAndNews from '../../components/Boxes/articlesAndNews';

import bgMobile from '../../assets/images/bg_servicos_mobile.jpg';
import bg from '../../assets/images/bg_servicos.jpg';
import BoxService from '../../components/Boxes/services';
import ReplaceImageUrlService from '../../services/ReplaceImageUrlService';
// import icone02 from '../../assets/images/icone_servicos02.png';
// import icone03 from '../../assets/images/icone_servicos03.png';

// import sobre01 from '../../assets/images/sobre01.png';
// import sobre02 from '../../assets/images/sobre02.png';
// import sobre03 from '../../assets/images/sobre03.png';
// import sobre04 from '../../assets/images/sobre04.png';

function Services({ match }) {
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [backgroundText, setBackgroundText] = useState('');
  const [contentTitle, setContentTitle] = useState('');
  const [contentParagraphs, setContentParagraphs] = useState('');
  const [contentImages, setContentImages] = useState('');
  const [block2Title, setBlock2Title] = useState('');
  const [block2Subtitle, setBlock2Subtitle] = useState('');
  const [bannerTitle, setBannerTitle] = useState('');
  const [bannerBackgroundText, setBannerBackgroundText] = useState('');
  const [bannerPage, setBannerPage] = useState('');
  const [bannerPageMobile, setBannerPageMobile] = useState('');
  const [telefone, setTelefone] = useState('');
  const [textsAndQuestions, setTextsAndQuestions] = useState([]);
  const [topics, setTopics] = useState([]);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [ourServices, setOurServices] = useState([]);

  function contactCad(e) {
    e.preventDefault();
    const postData = {
      nome: e.target.nomeAvaliacao.value,
      email: e.target.emailAvaliacao.value,
      telefone: e.target.telefoneAvaliacao.value,
      mensagem: e.target.mensagemAvaliacao.value,
      origem: 'servicos'
    };
    api
      .post(`leads`, postData)
      .then(({ data }) => {
        toast.success('Contato enviado com sucesso!', {
          position: toast.POSITION.TOP_RIGHT
        });
      })
      .catch(error => {
        toast.error(error.response.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      });

    e.target.nomeAvaliacao.value = '';
    e.target.emailAvaliacao.value = '';
    e.target.telefoneAvaliacao.value = '';
    e.target.mensagemAvaliacao.value = '';
  }

  useEffect(() => {
    if (match.params.slug) {
      api
        .get(`/servicos-paginas?slug=${match.params.slug}`)
        .then(({ data: rawData }) => {
          const data = rawData[0];

          if (!data) return;
          setTitle(data.titulo);
          setSubtitle(data.subtitulo);
          setBackgroundText(data.textoBackground);
          setContentTitle(data.conteudoTitulo);
          setContentParagraphs([
            data.conteudoParagrafo1,
            data.conteudoParagrafo2,
            data.conteudoParagrafo3
          ]);
          setBlock2Title(data.bloco2Titulo);
          setBlock2Subtitle(data.bloco2Subtitulo);
          setBannerTitle(data.bannerTitulo);
          setBannerBackgroundText(data.bannerTextoBackground);
          setTextsAndQuestions(data.textosComPerguntas);
          setTopics(data.topico);
          setContentImages(data.conteudoImagens);
          setContentTitle(data.conteudoTitulo);
          setBannerPage(data.bannerDesktop.url);
          setBannerPageMobile(data.bannerMobile.url);
          setContentParagraphs([
            data.conteudoParagrafo1,
            data.conteudoParagrafo2,
            data.conteudoParagrafo3
          ]);
          setLoading(false);
          window.scrollTo(0, 0);

          const receivedMessages = [];

          let imageIteration = 1;
          let comparassion = 1;

          for (let i = 1; i <= 8; i++) {
            switch (imageIteration) {
              case 1:
                if (data.imagensMasonry[`imagemVertical${comparassion}`])
                  receivedMessages.push(
                    data.imagensMasonry[`imagemVertical${comparassion}`]
                  );
                imageIteration += 1;

                break;
              case 2:
                if (data.imagensMasonry[`imagemHorizontal${comparassion}`])
                  receivedMessages.push(
                    data.imagensMasonry[`imagemHorizontal${comparassion}`]
                  );
                imageIteration += 1;

                break;
              case 3:
                if (data.imagensMasonry[`imagemHorizontal${comparassion + 1}`])
                  receivedMessages.push(
                    data.imagensMasonry[`imagemHorizontal${comparassion + 1}`]
                  );
                imageIteration += 1;

                break;
              case 4:
                if (data.imagensMasonry[`imagemVertical${comparassion + 1}`])
                  receivedMessages.push(
                    data.imagensMasonry[`imagemVertical${comparassion + 1}`]
                  );
                imageIteration = 1;
                comparassion += 2;

                break;
              default:
                break;
            }
          }

          setImages(receivedMessages);
        });
    } else {
      api.get('/homepage').then(({ data }) => {
        setOurServices(data.nossosServicos);
        setLoading(false);
      });
    }
  }, []);

  const arrangeImages = unorganizedImages => {
    if (!unorganizedImages) return [];

    const arrangedImages = [];
    let iterationArray = [];
    let iteration = 1;

    unorganizedImages.forEach(iterationImage => {
      if (iteration <= 4) {
        iterationArray.push(iterationImage);
        iteration += 1;
      } else {
        arrangedImages.push(iterationArray);
        iteration = 2;
        iterationArray = [iterationImage];
      }
    });

    arrangedImages.push(iterationArray);

    return arrangedImages;
  };

  const items = arrangeImages(images).map((imageGrid, indexGrid) => (
    <div className='grid-container' key={`grid_${indexGrid}`}>
      {imageGrid.map((iterationImage, index) => (
        <div className={`image0${index + 1}`} key={`image0${index + 1}`}>
          <img src={ReplaceImageUrlService(iterationImage.url)} alt={`sobre0${index + 1}`} />
        </div>
      ))}
    </div>
  ));

  return (
    <main className='internal'>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Helmet>
            <title>Serviços - {process.env.REACT_APP_TITLE}</title>
          </Helmet>
          {!match.params.slug ? (
            <BannersPages
              {...{
                bgMobile,
                bg,
                text: 'Servicos',
                title: 'Serviços',
                subtitle: ''
              }}
            />
          ) : (
            <BannersPages
              {...{
                bgMobile: bannerPageMobile,
                bg: bannerPage,
                text: bannerBackgroundText,
                title: bannerTitle,
                subtitle: ''
              }}
            />
          )}
          {!match.params.slug ? (
            <section className='content services'>
              <span className='pattern' />
              <CenterTitle
                {...{
                  text: 'Servicos',
                  title: 'Nossos',
                  subtitle: 'Serviços'
                }}
              />
              <div className='container'>
                <div className='content services_boxes'>
                  {ourServices.map(({ id, titulo, descricao, link }) => (
                    <BoxService
                      key={`box_${id}`}
                      {...{
                        title: titulo,
                        slug: link,
                        description: descricao,
                        pureLink: true
                      }}
                    />
                  ))}
                </div>
              </div>
            </section>
          ) : (
            <>
              <section className='internalPage clinica'>
                <span className='patternLeft' />
                <span className='patternRight' />
                <div className='container'>
                  <CenterTitle
                    {...{
                      divClass: 'minorTitle noMargin',
                      text: backgroundText,
                      title: subtitle,
                      subtitle: title
                    }}
                  />
                </div>
                <div className='container flexRow noTitles'>
                  {contentParagraphs.map((paragraph, index) => (
                    <BoxWeAre
                      key={`boxWeAre_${index}`}
                      {...{
                        title: contentTitle,
                        description: paragraph
                      }}
                    />
                  ))}
                </div>
                <div className='container'>
                  <div className='slider_aboutUs'>
                    <AliceCarousel
                      autoPlayInterval={5000}
                      items={items}
                      animationType='fade'
                      // autoPlay
                      infinite
                      disableDotsControls
                    />
                  </div>
                </div>
              </section>
              <section className='clinica__title'>
                <div className='no_text'>
                  {(topics.length >= 1 || textsAndQuestions.length >= 1) && (
                    <StrokeTitleCenter
                      {...{
                        divClass: 'minorTitle',
                        letter: block2Subtitle.substring(0, 1),
                        letterClass: '',
                        title: block2Subtitle,
                        subtitle: block2Title
                      }}
                    />
                  )}
                  {textsAndQuestions && textsAndQuestions.length >= 1 ? (
                    <div className='container flexRow baseline'>
                      {textsAndQuestions.map(({ id, texto, pergunta }) => (
                        <div
                          className='container_service'
                          key={`cont_serv_${id}`}
                        >
                          <div className='content clinica'>
                            <div
                              className='content__text'
                              data-aos='fade-up'
                              data-aos-offset='200'
                              data-aos-delay='50'
                              data-aos-duration='700'
                              data-aos-easing='ease-in-out'
                            >
                              <p>{texto}</p>
                            </div>
                            <div
                              className='content__text'
                              data-aos='fade-up'
                              data-aos-offset='200'
                              data-aos-delay='50'
                              data-aos-duration='700'
                              data-aos-easing='ease-in-out'
                            >
                              <Accordion>
                                {pergunta.map(iterationQuestion => (
                                  <AccordionItem key={iterationQuestion.id}>
                                    <AccordionItemHeading>
                                      <AccordionItemButton>
                                        {iterationQuestion.pergunta}
                                      </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                      <p>{iterationQuestion.resposta}</p>
                                    </AccordionItemPanel>
                                  </AccordionItem>
                                ))}
                              </Accordion>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className='no_text'>-</div>
                  )}
                </div>
                <div className='content about_options_bg'>
                  {topics && topics.length >= 1 && (
                    <div className='container'>
                      <div className='content__text'>
                        {topics.map(({ id, titulo, texto, imagem }) => (
                          <About
                            key={`about_${id}`}
                            {...{
                              image: imagem.url,
                              title: titulo,
                              description: texto
                            }}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </section>
              <section className='internalPage'>
                <div className='container flexRow'>
                  <div>
                    <CenterTitle
                      {...{
                        divClass: 'leftText',
                        text: 'Fale conosco',
                        title: 'Fale',
                        subtitle: 'Conosco'
                      }}
                    />
                    <p
                      className='content__text black paddingRight'
                      data-aos='fade-up'
                      data-aos-offset='200'
                      data-aos-delay='50'
                      data-aos-duration='700'
                      data-aos-easing='ease-in-out'
                    >
                      O Instituto Tofaneto é a junção de bagagem e conhecimento
                      no ramo da Odontologia, portanto, queremos uma experiência
                      digital, para você traze-la mais perto de sua clínica.
                      Entre em contato conosco e descubra essa nova realidade.
                    </p>
                  </div>
                  <form
                    action='#!'
                    className='contact_services'
                    data-aos='fade-up'
                    data-aos-offset='200'
                    data-aos-delay='50'
                    data-aos-duration='700'
                    data-aos-easing='ease-in-out'
                    onSubmit={contactCad}
                  >
                    <div className='baseForm'>
                      <div className='control'>
                        <label htmlFor='nome'>
                          <input
                            type='text'
                            name='nomeAvaliacao'
                            id='nomeAvaliacao'
                            placeholder='Insira seu nome'
                          />
                        </label>
                      </div>
                      <div className='control'>
                        <label htmlFor='telefone'>
                          <input
                            type='tel'
                            name='telefoneAvaliacao'
                            id='telefoneAvaliacao'
                            placeholder='Insira seu telefone'
                            value={telefone}
                            onChange={({ target }) => {
                              setTelefone(
                                mask(unMask(target.value), [
                                  '(99) 9999-9999',
                                  '(99) 99999-9999'
                                ])
                              );
                            }}
                          />
                        </label>
                      </div>
                      <div className='control'>
                        <label htmlFor='email'>
                          <input
                            type='email'
                            name='emailAvaliacao'
                            id='emailAvaliacao'
                            placeholder='Insira seu e-mail'
                          />
                        </label>
                      </div>
                      <div className='control'>
                        <label htmlFor='mensagemAvaliacao'>
                          <textarea
                            id='mensagemAvaliacao'
                            name='mensagemAvaliacao'
                            placeholder='Escreva sua mensagem'
                          />
                        </label>
                      </div>
                      <div className='control'>
                        <input type='submit' value='Enviar' />
                      </div>
                    </div>
                  </form>
                </div>
              </section>
            </>
          )}
          <ArticlesAndNews />
          {/* <Instagram /> */}
        </>
      )}
    </main>
  );
}

export default Services;
