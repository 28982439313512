import React from 'react';
import { Link } from 'react-router-dom';

function Services({ title, description, slug, pureLink }) {
  return (
    <div
      className='servicesBox'
      data-aos='fade-up'
      data-aos-offset='200'
      data-aos-delay='50'
      data-aos-duration='700'
      data-aos-easing='ease-in-out'
    >
      <strong>{title}</strong>
      <p>{description}</p>
      <Link to={pureLink ? slug : `servicos/${slug}`} className='more dark'>
        Saiba mais
      </Link>
    </div>
  );
}

export default Services;
