import React from 'react';
import PartnersTitle from '../Titles/partners';
import PartnersSlider from '../Sliders/partners';

function Partners({ partners }) {
  return (
    <section className='content partners'>
      <PartnersTitle
        {...{
          title: 'Parceiros',
          subtitle: 'Parceiros'
        }}
      />
      <div className='container'>
        <PartnersSlider {...{ partners }} />
      </div>
    </section>
  );
}

export default Partners;
