import React from 'react';

import { Link } from 'react-router-dom';
import ReplaceImageUrlService from '../../services/ReplaceImageUrlService';

function WeAre({
  professor,
  type,
  image,
  link,
  title,
  description,
  price,
  slug
}) {
  return (
    <div className='course'>
      <div className='course_image'>
        <picture>
          <img src={ReplaceImageUrlService(image)} alt={title} title={title} />
          <span>Curso {type}</span>
        </picture>
      </div>
      <div className='course_details sell'>
        <div className='course_details_professor'>
          Ministrado por {professor}
        </div>
        <strong className='course_details_title'>{title}</strong>
        <p className='course_details_description'>{description}</p>
        <p className='course_details_price'>
          R$ {price}
          <sup>,00</sup>
        </p>
        {link && (
          <Link to={`/cursos/${slug}`} className='more_course dark'>
            Saiba mais
          </Link>
        )}
      </div>
    </div>
  );
}

export default WeAre;
