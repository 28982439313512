const ReplaceImageUrlService = (image) => {
  if (typeof image !== 'string') return image;

  // if image is base 64
  if (image.includes('data:image')) {
    return image;
  }

  if (!image.includes('/uploads'))
    return image;

  // if image url doesnt have a host url
  if (image.startsWith('/uploads')) {
    return `https://api.tofainstituto.com.br${image}`;
  }

  return image;
}

export default ReplaceImageUrlService;