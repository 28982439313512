import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import facebook from '../../assets/images/facebook_redes.png';
import instagram from '../../assets/images/instagram_redes.png';
import youtube from '../../assets/images/youtube_redes.png';
import markdigx from '../../assets/images/logo_markdigx.png';
import api from '../../services/api';
import Loading from '../Loading';
import ReplaceImageUrlService from '../../services/ReplaceImageUrlService';

function Footer() {
  // const page = document.location.pathname.replace('/', '');
  const page = document.location.pathname.split('/').pop();
  const [instituto, setInstituto] = useState({});
  const [loadFooter, setLoadFooter] = useState(true);

  function newsletterCad(e) {
    e.preventDefault();
    const postData = {
      email: e.target.emailNewsletter.value
    };
    api
      .post(`newsletter-inscricoes`, postData)
      .then(({ data }) => {
        toast.success('Newsletter cadastrado com sucesso!', {
          position: toast.POSITION.TOP_RIGHT
        });
      })
      .catch(error => {
        toast.error(
          error.response.data.message === 'Inscrição já existente'
            ? error.response.data.message
            : 'Erro ao cadastrar, tente novamente.',
          {
            position: toast.POSITION.TOP_RIGHT
          }
        );
      });

    e.target.emailNewsletter.value = '';
  }

  function contactCadFooter(e) {
    e.preventDefault();
    const postData = {
      nome: e.target.nomeContato.value,
      email: e.target.emailContato.value,
      mensagem: e.target.mensagemContato.value
    };
    // console.log(postData);
    api
      .post(`leads`, postData)
      .then(({ data }) => {
        toast.success('Contato enviado com sucesso!', {
          position: toast.POSITION.TOP_RIGHT
        });
      })
      .catch(error => {
        toast.error(error.response.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      });

    e.target.nomeContato.value = '';
    e.target.emailContato.value = '';
    e.target.mensagemContato.value = '';
  }

  useEffect(() => {
    api.get(`instituto`).then(({ data }) => {
      setInstituto(data);
      setLoadFooter(false);
    });
  }, []);

  return (
    <footer className={`footer ${page}`}>
      {loadFooter ? (
        <Loading />
      ) : (
        <>
          <div className='container'>
            <section>
              <div className='contactForms'>
                <div className='newsletter'>
                  <form
                    action='#!'
                    className='newsletter'
                    onSubmit={e => {
                      newsletterCad(e);
                    }}
                  >
                    <p>Assine a nossa</p>
                    <strong className='newstitle'>Newsletter</strong>
                    <div className='baseForm'>
                      <div className='control'>
                        <label htmlFor='email'>
                          <input
                            type='email'
                            name='emailNewsletter'
                            id='emailNewsletter'
                            placeholder='E-mail'
                          />
                        </label>
                      </div>
                      <div className='control'>
                        <input type='submit' value='Assinar' />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className='contactForm'>
                <form
                  action='#!'
                  className='contact'
                  onSubmit={contactCadFooter}
                >
                  <strong>Fale conosco</strong>
                  <div className='baseForm'>
                    <div className='control'>
                      <label htmlFor='nomeContato'>
                        Nome
                        <input
                          type='text'
                          name='nomeContato'
                          id='nomeContato'
                          placeholder='Insira seu nome'
                        />
                      </label>
                    </div>
                    <div className='control'>
                      <label htmlFor='emailContato'>
                        Email
                        <input
                          type='email'
                          name='emailContato'
                          id='emailContato'
                          placeholder='Insira seu e-mail'
                        />
                      </label>
                    </div>
                    <div className='control'>
                      <label htmlFor='mensagemContato'>
                        Mensagem
                        <textarea
                          name='mensagemContato'
                          id='mensagemContato'
                          placeholder='Escreva sua mensagem'
                        />
                      </label>
                    </div>
                    <div className='control'>
                      <input type='submit' value='Enviar' />
                    </div>
                  </div>
                </form>
              </div>
              <div className='boxes-social'>
                <div className='ourContacts'>
                  <strong>Nossos Contatos</strong>
                  <a href={`mailto:${instituto.email}`} className='email'>
                    {instituto.email}
                  </a>
                  <a href={`tel:0${instituto.telefone.replace(/\\D/g, '')}`}>
                    {instituto.telefone}
                  </a>
                  <a href={`tel:0${instituto.whatsapp.replace(/\\D/g, '')}`}>
                    {instituto.whatsapp} (whatsapp)
                  </a>
                  <p>{instituto.endereco}</p>
                </div>
                <div className='ourSocial'>
                  <strong>Nossas Redes Sociais</strong>
                  <div className='ourContactsSocial'>
                    <a
                      href={instituto.facebook}
                      rel='noreferrer'
                      target='_blank'
                    >
                      <img src={ReplaceImageUrlService(facebook)} alt='Facebook' title='Facebook' />
                    </a>
                    <a
                      href={instituto.instagram}
                      rel='noreferrer'
                      target='_blank'
                    >
                      <img src={ReplaceImageUrlService(instagram)} alt='Instagram' title='Instagram' />
                    </a>
                    <a
                      href={instituto.youtube}
                      rel='noreferrer'
                      target='_blank'
                    >
                      <img src={ReplaceImageUrlService(youtube)} alt='Youtube' title='Youtube' />
                    </a>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </>
      )}
      <div className='container'>
        <div className='copyright'>
          <div className='info'>
            Todos os direitos reservados © {new Date().getFullYear()} -
            Instituto Tofa
          </div>
          {/* <a href='https://markdigx.com.br' rel='noreferrer' target='_blank'>
            <img
              src={markdigx}
              alt='Markdigx'
              title='Markdigx'
              className='logoMark'
            />
          </a> */}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
