import { Helmet } from 'react-helmet';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { mask, unMask } from 'remask';
import api from '../../services/api';
import BannersPages from '../../components/Sliders/pages';
import Loading from '../../components/Loading';

import bgMobile from '../../assets/images/bg_contato_mobile.jpg';
import bg from '../../assets/images/bg_contato.jpg';
import phone from '../../assets/images/contato_phone.png';
import envelope from '../../assets/images/contato_envelope.png';
import pin from '../../assets/images/contato_pin.png';

function AboutUs() {
  const [loading, setLoading] = useState(true);
  const [instituto, setInstituto] = useState({});
  const [telefone, setTelefone] = useState('');

  useEffect(() => {
    api.get(`instituto`).then(({ data }) => {
      setInstituto(data);
      setLoading(false);
    });
  }, []);

  function contactCad(e) {
    e.preventDefault();
    const postData = {
      nome: e.target.nome.value,
      email: e.target.email.value,
      telefone: e.target.telefone.value,
      mensagem: e.target.mensagem.value
    };
    // console.log(postData);
    api
      .post(`leads`, postData)
      .then(({ data }) => {
        toast.success('Contato enviado com sucesso!', {
          position: toast.POSITION.TOP_RIGHT
        });
      })
      .catch(error => {
        toast.error(error.response.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      });
    e.target.nome.value = '';
    e.target.email.value = '';
    e.target.telefone.value = '';
    e.target.mensagem.value = '';
  }

  return (
    <main>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Helmet>
            <title>Contato - {process.env.REACT_APP_TITLE}</title>
          </Helmet>
          <BannersPages
            {...{
              bgMobile,
              bg,
              text: 'Fale Conosco',
              title: 'Contato',
              subtitle: ''
            }}
          />
          <section className='internalPageContact'>
            <form
              action=''
              className='contact contactPage'
              data-aos='fade-up'
              data-aos-offset='200'
              data-aos-delay='50'
              data-aos-duration='700'
              data-aos-easing='ease-in-out'
              onSubmit={contactCad}
            >
              <div className='baseForm'>
                <div className='control'>
                  <label htmlFor='nome'>
                    <input
                      type='text'
                      name='nome'
                      id='nome'
                      placeholder='Nome'
                    />
                  </label>
                </div>
                <div className='control'>
                  <label htmlFor='telefone'>
                    <input
                      type='tel'
                      name='telefone'
                      id='telefone'
                      placeholder='Telefone'
                      value={telefone}
                      onChange={({ target }) => {
                        setTelefone(
                          mask(unMask(target.value), [
                            '(99) 9999-9999',
                            '(99) 99999-9999'
                          ])
                        );
                      }}
                    />
                  </label>
                </div>
                <div className='control'>
                  <label htmlFor='email'>
                    <input
                      type='email'
                      name='email'
                      id='email'
                      placeholder='E-mail'
                    />
                  </label>
                </div>
                <div className='control'>
                  <label htmlFor='mensagem'>
                    <textarea
                      id='mensagem'
                      name='mensagem'
                      placeholder='Mensagem'
                    />
                  </label>
                </div>
                <div className='control'>
                  <input type='submit' value='Enviar' className='contactPage' />
                </div>
              </div>
            </form>
            <section
              className='contactDetails'
              data-aos='fade-up'
              data-aos-offset='200'
              data-aos-delay='50'
              data-aos-duration='700'
              data-aos-easing='ease-in-out'
            >
              <div className='contactInfos'>
                <img src={phone} alt='Telefone' />
                <div>
                  <a href={`tel:0${instituto.telefone.replace(/\\D/g, '')}`}>
                    {instituto.telefone}
                  </a>
                  <a href={`tel:0${instituto.whatsapp.replace(/\\D/g, '')}`}>
                    {instituto.whatsapp} (whatsapp)
                  </a>
                </div>
              </div>
              <div className='contactInfos'>
                <img src={envelope} alt='E-mail' />
                <div>
                  <a href={`mailto:${instituto.email}`}>{instituto.email}</a>
                </div>
              </div>
              <div className='contactInfos'>
                <img src={pin} alt='Endereço' />
                <div>
                  <p>{instituto.endereco}</p>
                </div>
              </div>
            </section>
          </section>
        </>
      )}
    </main>
  );
}

export default AboutUs;
