import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import AOS from 'aos';
import { ToastContainer } from 'react-toastify';
import TagManager from 'react-gtm-module';

import './assets/styles/main.scss';
import 'aos/dist/aos.css';

import Menu from './components/Menu';
import Footer from './components/Footer';

import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Expertise from './pages/Expertise';
import Clinic from './pages/Clinic';
import Laboratory from './pages/Laboratory';
import Services from './pages/Services';
import Courses from './pages/Courses';
import CoursesDetails from './pages/Courses/details';
import Blog from './pages/Blog';
import Post from './pages/Blog/post';
import Podcasts from './pages/Blog/podcasts';
import Cart from './pages/Cart';
import Live from './pages/Live';
import Agenda from './pages/Agenda';
import Contact from './pages/Contact';
import Dashboard from './pages/Dashboard';

AOS.init();

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: 'GTM-5SXT5LL'
    };
    TagManager.initialize(tagManagerArgs);

    setLoading(false);
  });

  return (
    <div className='wrapper'>
      <ToastContainer />
      <Menu />
      <BrowserRouter>
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/sobre-nos' exact component={AboutUs} />
          <Route path='/experiencia-formacoes' exact component={Expertise} />
          <Route path='/clinica' exact component={Clinic} />
          <Route path='/laboratorio' exact component={Laboratory} />
          <Route path='/servicos' exact component={Services} />
          <Route path='/servicos/:slug' exact component={Services} />
          <Route path='/cursos' exact component={Courses} />
          <Route path='/cursos/:slug' exact component={CoursesDetails} />
          <Route path='/blog' exact component={Blog} />
          <Route path='/blog/:slug' exact component={Post} />
          <Route path='/podcasts' exact component={Podcasts} />
          <Route path='/cursos/:slug/pagamento' exact component={Cart} />
          <Route path='/live' exact component={Live} />
          <Route path='/agenda' exact component={Agenda} />
          <Route path='/contato' exact component={Contact} />
          <Route path='/dashboard' exact component={Dashboard} />
        </Switch>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
