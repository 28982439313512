import React, { useState, useEffect } from 'react';
import ReactCardCarousel from 'react-card-carousel';
import ReactPlayer from 'react-player';

import { Helmet } from 'react-helmet';
import api from '../../services/api';
import ErrorMessageService from '../../services/ErrorMessageService';

import BannersHome from '../../components/Sliders/home';
import VideoSlider from '../../components/Sliders/videos';
import TreatmentSlider from '../../components/Sliders/treatments';
import StrokeTitle from '../../components/Titles/stroke';
import StrokeTitleCenter from '../../components/Titles/strokeCenter';
import CenterTitle from '../../components/Titles/center';
import BoxService from '../../components/Boxes/services';
import BoxCourse from '../../components/Boxes/courses';
// import Instagram from '../../components/Boxes/instagram';
import Cases from '../../components/Boxes/cases';
import ArticlesAndNews from '../../components/Boxes/articlesAndNews';
import Partners from '../../components/Boxes/partners';
import Loading from '../../components/Loading';

import notebook from '../../assets/images/notebook_sobrenos.png';
import insta from '../../assets/images/instagram_image.jpg';
import play from '../../assets/images/play.png';
import BoxVideo from '../../components/Boxes/videos';
import ReplaceImageUrlService from '../../services/ReplaceImageUrlService';

function Home() {
  const [articles, setArticles] = useState([]);
  const [bannerSlider, setBannerSlider] = useState([]);
  const [facebook, setFacebook] = useState();
  const [instagram, setInstagram] = useState('');
  const [youtube, setYoutube] = useState('');
  const [telephone, setTelephone] = useState('');
  const [email, setEmail] = useState('');
  const [aboutUs1, setAboutUs1] = useState('');
  const [aboutUs2, setAboutUs2] = useState('');
  const [sobreNosCarrosel, setSobreNosCarrosel] = useState([]);
  const [ourCoursesParagraph1, setOurCoursesParagraph1] = useState('');
  const [ourCoursesParagraph2, setOurCoursesParagraph2] = useState('');
  const [howWeDoItDescription, setHowWeDoItDescription] = useState('');
  const [howWeDoItVideoLink, setHowWeDoItVideoLink] = useState('');
  const [ourCasesDescription, setOurCasesDescription] = useState('');
  const [ourCasesImages, setOurCasesImages] = useState([]);
  const [ourServices, setOurServices] = useState([]);
  const [topics, setTopics] = useState([]);
  const [ourTreatments, setOurTreatments] = useState([]);
  const [videos, setVideos] = useState([]);
  const [partners, setPartners] = useState([]);
  const [aboutUsCarousel, setAboutUsCarousel] = useState([]);
  const [qtde, setSobreNosCarroselQtd] = useState(0);
  const [loading, setLoading] = useState(true);
  const [playVideo, setPlayVideo] = useState(false);
  const [shouldPlay, updatePlayState] = useState(false);
  const [carousel, setCarousel] = useState();
  const [activeCard, setActiveCard] = useState();
  const [homeTitulo, setHomeTitulo] = useState();
  const [homeSubtitulo, setHomeSubtitulo] = useState();

  useEffect(() => {
    // sessionStorage.clear();
    api
      .get('homepage')
      .then(({ data }) => {
        setBannerSlider(data.bannerSlider);
        setFacebook(data.facebook);
        setInstagram(data.instagram);
        setYoutube(data.youtube);
        setTelephone(data.telefone);
        setEmail(data.email);
        setAboutUs1(data.sobreNos1);
        setAboutUs2(data.sobreNos2);
        setSobreNosCarrosel(data.sobreNosCarrosel);
        setOurCoursesParagraph1(data.nossosCursosParagrafo1);
        setOurCoursesParagraph2(data.nossosCursosParagrafo2);
        setHowWeDoItDescription(data.comoFazemosDescricao);
        // setHowWeDoItVideoLink(data.comoFazemosVideoLink);
        setHowWeDoItVideoLink(data.comoFazemosVideoLink.split('/').pop());
        setOurCasesDescription(data.nossosCasesDescricao);
        setOurCasesImages(data.nossosCasesImagens);
        setOurServices(data.nossosServicos);
        setTopics(data.topicos);
        setVideos(data.videos);
        setPartners(data.parceiros);
        setAboutUsCarousel(data.sobreNosCarrosel);
        setHomeTitulo(data?.homeTitulo);
        setHomeSubtitulo(data?.homeSubtitulo);
        setLoading(false);
      })
      .catch(error => ErrorMessageService(error));

    api.get('artigos?_sort=id:ASC').then(({ data }) => {
      setArticles(data);
    });

    api.get('servicos-paginas').then(({ data }) => {
      setOurTreatments(data);
    });
  }, []);

  function autoplayVideo() {
    updatePlayState(true);
  }

  function stopVideo() {
    updatePlayState(false);
  }

  function cardChange(index) {
    carousel.goTo(index);
    setActiveCard(index);
  }

  function cardAutoChange(index) {
    // eslint-disable-next-line
    console.log(index);
  }

  return (
    <main className='App'>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Helmet>
            <title>{process.env.REACT_APP_TITLE}</title>
          </Helmet>
          <BannersHome
            {...{
              sliders: bannerSlider,
              facebook,
              instagram,
              youtube,
              email,
              telephone
            }}
          />
          <section className='content about'>
            <div className='container'>
              <StrokeTitle
                {...{
                  divClass: 'sobreNos',
                  letter: 'S',
                  title: 'Sobre',
                  subtitle: 'Nós'
                }}
              />
              <div>
                <p
                  className='content__text'
                  data-aos='fade-up'
                  data-aos-offset='200'
                  data-aos-delay='50'
                  data-aos-duration='700'
                  data-aos-easing='ease-in-out'
                >
                  {aboutUs1}
                </p>
                <p
                  className='content__text'
                  data-aos='fade-up'
                  data-aos-offset='200'
                  data-aos-delay='50'
                  data-aos-duration='700'
                  data-aos-easing='ease-in-out'
                >
                  {aboutUs2}
                </p>
                <a
                  href='./sobre-nos'
                  className='more'
                  data-aos='fade-up'
                  data-aos-offset='200'
                  data-aos-delay='50'
                  data-aos-duration='700'
                  data-aos-easing='ease-in-out'
                >
                  Saiba mais
                </a>
              </div>
              <div>
                <div className='cardSlider'>
                  <ReactCardCarousel
                    ref={Carousel => setCarousel(Carousel)}
                    // autoplay
                    // autoplay_speed={5000}
                    afterChange={e => {
                      cardAutoChange(e);
                    }}
                  >
                    {sobreNosCarrosel &&
                      sobreNosCarrosel.map((img, index) => (
                        <div className='cardStyle' key={`sobrenos_${index}`}>
                          <img src={ReplaceImageUrlService(img.url)} alt='Insta' />
                        </div>
                      ))}
                  </ReactCardCarousel>
                  <div className='cardPagination'>
                    {sobreNosCarrosel &&
                      sobreNosCarrosel.map((img, index) => (
                        <button
                          type='button'
                          key={`video_${index}`}
                          className={activeCard !== index || 'active'}
                          onClick={() => {
                            cardChange(index);
                          }}
                        >
                          {img.id}
                        </button>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className='content services'>
            <span className='pattern' />
            <CenterTitle
              {...{
                text: 'Servicos',
                title: 'Nossos',
                subtitle: 'Serviços'
              }}
            />
            <div className='container'>
              <div className='content services_boxes'>
                {ourServices.map(({ id, titulo, descricao, link }) => (
                  <BoxService
                    key={id}
                    {...{
                      title: titulo,
                      slug: link,
                      description: descricao,
                      pureLink: true
                    }}
                  />
                ))}
              </div>
            </div>
          </section>
          <section className='content coursesHome'>
            <div className='container content coursesHome_boxes'>
              <StrokeTitle
                {...{
                  letter: 'N',
                  letterClass: 'white',
                  title: 'Nossos',
                  subtitle: 'Cursos'
                }}
              />
              <div className='content courses_about'>
                <div>
                  <p
                    className='content__text'
                    data-aos='fade-up'
                    data-aos-offset='200'
                    data-aos-delay='50'
                    data-aos-duration='700'
                    data-aos-easing='ease-in-out'
                  >
                    {ourCoursesParagraph1}
                  </p>
                  <p
                    className='content__text'
                    data-aos='fade-up'
                    data-aos-offset='200'
                    data-aos-delay='50'
                    data-aos-duration='700'
                    data-aos-easing='ease-in-out'
                  >
                    {ourCoursesParagraph2}
                  </p>
                  <a
                    href='./cursos'
                    className='more'
                    data-aos='fade-up'
                    data-aos-offset='200'
                    data-aos-delay='50'
                    data-aos-duration='700'
                    data-aos-easing='ease-in-out'
                  >
                    Saiba mais
                  </a>
                </div>
              </div>
              <div className='stripes'>
                <img
                  src={notebook}
                  alt='Notebook'
                  className='notebook'
                  data-aos='fade-up'
                  data-aos-offset='200'
                  data-aos-delay='50'
                  data-aos-duration='700'
                  data-aos-easing='ease-in-out'
                />
              </div>
            </div>
            <div className='container'>
              <div className='options'>
                {topics.map(({ id, titulo, descricao }) => (
                  <BoxCourse
                    key={id}
                    {...{
                      title: titulo,
                      description: descricao
                    }}
                  />
                ))}
              </div>
            </div>
            <div className='whatWeDoVideo'>
              <div className={`whatWeDo ${playVideo}`}>
                <StrokeTitle
                  {...{
                    letter: 'C',
                    letterClass: 'white',
                    title: 'Como nós',
                    subtitle: 'Fazemos'
                  }}
                />
                <p className='content__text content__text_whatWeDo'>
                  {howWeDoItDescription}
                </p>
                <div>
                  <a
                    href='#!'
                    onClick={() => {
                      setPlayVideo(true);
                      autoplayVideo();
                    }}
                  >
                    <img src={play} alt='Assista o vídeo' className='play' />
                  </a>
                  <a
                    href='#!'
                    className='play_link'
                    onClick={() => {
                      setPlayVideo(true);
                      autoplayVideo();
                    }}
                  >
                    Click no play
                  </a>
                </div>
              </div>
              <div
                id='whatWeDoVideo'
                className={`whatWeDo_displayVideo ${playVideo}`}
              >
                <ReactPlayer
                  className='react-player'
                  playing={shouldPlay}
                  url={`https://www.youtube.com/watch?v=${howWeDoItVideoLink}`}
                />
              </div>
              <a
                href='#!'
                className={`play_link play_link_whatWeDoVideo ${playVideo}`}
                onClick={() => {
                  setPlayVideo(false);
                  stopVideo();
                }}
              >
                Fechar o vídeo
              </a>
            </div>
          </section>
          <section className='content treatments' id='tratamentos'>
            <StrokeTitleCenter
              {...{
                letter: 'N',
                letterClass: '',
                title: 'Nossos',
                subtitle: 'Tratamentos'
              }}
            />
            <div className='container'>
              <TreatmentSlider {...{ treatments: ourTreatments }} />
            </div>
          </section>
          <section className='content channel'>
            <StrokeTitleCenter
              {...{
                divClass: 'transparent',
                letter:
                  // prettier hell
                  (homeSubtitulo &&
                    homeTitulo &&
                    homeTitulo.charAt(0).toUpperCase()) ||
                  'D',
                letterClass: '',
                title: (homeSubtitulo && homeTitulo) || 'Depoimento De',
                subtitle: (homeTitulo && homeSubtitulo) || 'Alunos'
              }}
            />
            <div className='videoSliderBoxes'>
              <VideoSlider {...{ videos }} />
            </div>
          </section>
          <Cases {...{ ourCasesDescription, ourCasesImages }} />
          {articles.length >= 1 && <ArticlesAndNews {...{ articles }} />}
          <Partners {...{ partners }} />
          {/* <Instagram /> */}
        </>
      )}
    </main>
  );
}

export default Home;
