import tratamento from '../../assets/images/tratamento.jpg';

function BoxNews({ date, image, title, description }) {
  return (
    <div className='newsBox'>
      <div
        className='newsBox_image'
        style={{
          backgroundImage: `url(${image !== null ? image : tratamento})`
        }}
      />
      {/* <img */}
      {/*  src={image !== null ? image : tratamento} */}
      {/*  alt={title} */}
      {/*  title={title} */}
      {/* /> */}
      <span>{date}</span>
      <strong>{title}</strong>
      <p>{description}</p>
    </div>
  );
}

export default BoxNews;
