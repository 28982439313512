function CenterTitle({ divClass, text, title, subtitle }) {
  return (
    <div
      className={`centerTitle ${divClass}`}
      data-aos='fade-up'
      data-aos-offset='200'
      data-aos-delay='50'
      data-aos-duration='700'
      data-aos-easing='ease-in-out'
    >
      <span>{text}</span>
      <p>{title}</p>
      <strong>{subtitle}</strong>
    </div>
  );
}

export default CenterTitle;
