import React, { useEffect, useState } from 'react';

import AudioPlayer from 'react-modular-audio-player';

import play from '../../assets/images/player_play.png';
import pause from '../../assets/images/player_pause.png';
import api from '../../services/api';

// https://file-examples-com.github.io/uploads/2017/11/file_example_MP3_700KB.mp3
function Podcasts() {
  const [podcasts, setPodcasts] = useState([]);

  useEffect(() => {
    api.get('podcasts').then(({ data }) => setPodcasts(data));
  }, []);

  return (
    <div
      className='container'
      data-aos='fade-up'
      data-aos-offset='200'
      data-aos-delay='50'
      data-aos-duration='700'
      data-aos-easing='ease-in-out'
    >
      <div className='podcasts'>
        {podcasts.map(({ titulo, autor, link }) => (
          <div className='podcastPlayer'>
            <AudioPlayer
              audioFiles={[
                {
                  src: link,
                  title: titulo,
                  artist: autor
                }
              ]}
              playIcon={play}
              playHoverIcon={play}
              pauseIcon={pause}
              pauseHoverIcon={pause}
            />
          </div>
        ))}
        <div className='podcastPlayer'>
          <AudioPlayer
            audioFiles={[
              {
                src:
                  'https://file-examples-com.github.io/uploads/2017/11/file_example_MP3_700KB.mp3',
                title: 'Song',
                artist: 'Singer'
              }
            ]}
            playIcon={play}
            playHoverIcon={play}
            pauseIcon={pause}
            pauseHoverIcon={pause}
          />
        </div>
      </div>
    </div>
  );
}

export default Podcasts;
