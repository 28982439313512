import React, { useState } from 'react';
import ReactCardCarousel from 'react-card-carousel';
import BoxVideo from '../Boxes/videos';

function VideoSlider({ videos }) {
  const [carousel, setCarousel] = useState();
  const [active, setActive] = useState();

  function videoChange(index) {
    carousel.goTo(index);
    setActive(index);
  }

  const items = [
    videos &&
      videos.map((video, index) => (
        <div key={1} className='videoSlider'>
          <BoxVideo
            {...{
              // image: 'http://localhost:3000/assets/images/tratamento.jpg',
              index,
              image: `https://i3.ytimg.com/vi/${video.link
                .replace('watch?v=', '')
                .split('/')
                .pop()}/maxresdefault.jpg`,
              title: video.titulo,
              description: video.descricao,
              link: video.link
            }}
          />
        </div>
      ))
  ];

  return (
    <div>
      <ReactCardCarousel ref={Carousel => setCarousel(Carousel)}>
        {items}
      </ReactCardCarousel>
      <div className='videoPagination'>
        {videos &&
          videos.map((video, index) => (
            <button
              type='button'
              key={`video_${index}`}
              className={active !== index || 'active'}
              onClick={() => {
                videoChange(index);
              }}
            >
              {video.id}
            </button>
          ))}
      </div>
    </div>
  );
}

export default VideoSlider;
