import React, { useState, useEffect } from 'react';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';

import { Helmet } from 'react-helmet';
import api from '../../services/api';
import Loading from '../../components/Loading';

import BannersPages from '../../components/Sliders/pages';
// import Instagram from '../../components/Boxes/instagram';
import CourseDetails from '../../components/Boxes/courseDetails';
import CoursePayment from '../../components/Boxes/coursePayment';

import bgMobile from '../../assets/images/bg_servicos_mobile.jpg';
import bg from '../../assets/images/bg_servicos.jpg';
import curso from '../../assets/images/curso.jpg';

function Courses({ match }) {
  const [courseData, setCourseData] = useState({});
  const [loading, setLoading] = useState(true);
  const firstOpened = 0;

  useEffect(() => {
    api
      .get(`cursos?slug=${match.params.slug}`, {
        headers: {
          tofaauthorization: ''
        }
      })
      .then(({ data }) => {
        // console.log(data[0]);
        setCourseData(data[0]);

        setLoading(false);
        window.scrollTo(0, 0);
      });
  }, []);

  return (
    <main>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Helmet>
            <title>
              {courseData.bannerTitulo} {courseData.bannerSubtitulo} | Cursos -{' '}
              {process.env.REACT_APP_TITLE}
            </title>
          </Helmet>
          <BannersPages
            {...{
              bgMobile: courseData.bannerMobile
                ? courseData.bannerMobile.url
                : bgMobile,
              bg: courseData.bannerDesktop ? courseData.bannerDesktop.url : bg,
              text: courseData.bannerTextoBackground,
              title: courseData.bannerSubtitulo,
              subtitle: courseData.bannerTitulo
            }}
          />
          <section className='internalPage course_details'>
            <span className='patternLeft' />
            <span className='patternRight' />
            <div className='container flexRow start'>
              <div className='course_column_details'>
                <CourseDetails
                  key='course-intro'
                  {...{
                    title: 'Introdução ao Curso',
                    description: courseData.introducao
                  }}
                />
                <CourseDetails
                  key='course-details'
                  {...{
                    title: 'O que irá aprender',
                    description: courseData.oQueIraAprender
                  }}
                />
                <div className='courseDetailsBox_accordeon'>
                  <div className='course_accordeon_title'>
                    <strong>Currículo do curso (clique para expandir)</strong>
                    <strong>
                      {courseData.topico.length}{' '}
                      {courseData.topico.length > 1 ? `Tópicos` : `Tópico`}
                    </strong>
                  </div>
                  <Accordion allowMultipleExpanded>
                    {courseData.topico.map((topico, index) => (
                      <div key={`topico_${index}`}>
                        <AccordionItem uuid={index}>
                          <AccordionItemHeading>
                            <AccordionItemButton
                              // className={`asd_${index === 0 || true}`}
                              aria-disabled={index === 0 || true}
                              aria-expanded={index === 0 || true}
                            >
                              <span>{topico.titulo}</span>
                              <span className='white'>
                                <strong>+</strong>
                              </span>
                              <span className='whiteDetails'>
                                {topico.licao.length} Lições <strong>-</strong>
                              </span>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Accordion>
                              {topico.licao.map((licao, indexLicao) => (
                                <div key={`licao_${indexLicao}`}>
                                  <AccordionItem>
                                    <AccordionItemHeading>
                                      <AccordionItemButton
                                        aria-disabled={indexLicao === 0 || true}
                                        aria-expanded={indexLicao === 0 || true}
                                      >
                                        {licao.titulo}
                                      </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                      <p>{licao.descricao}</p>
                                    </AccordionItemPanel>
                                  </AccordionItem>
                                </div>
                              ))}
                            </Accordion>
                          </AccordionItemPanel>
                        </AccordionItem>
                      </div>
                    ))}
                  </Accordion>
                </div>
              </div>
              <CoursePayment
                {...{
                  slug: courseData.slug,
                  professor: courseData.doutor.nome,
                  professorImage: courseData.doutor.imagem
                    ? courseData.doutor.imagem.url
                    : 'https://d14d5ptqhh8ccm.cloudfront.net/5b6660b8_419f_4ce0_a6f9_0201f1ba782d_d142b0bc3f.jpg',
                  type: courseData.presencial ? 'Presencial' : 'Online',
                  externo: !!courseData.externo,
                  linkExterno: courseData.linkExterno,
                  image: courseData.imagem.url,
                  title: courseData.titulo,
                  price: courseData.valorAVista,
                  description: courseData.doutor.descricao,
                  paused: courseData.pausado
                }}
              />
            </div>
          </section>
          {/* <Instagram /> */}
        </>
      )}
    </main>
  );
}

export default Courses;
