import React, { useEffect, useState } from 'react';

import jwtDecode from 'jwt-decode';
import logo from '../../assets/images/logo_tofa.png';
import search from '../../assets/images/search.png';
import menu from '../../assets/images/menu.png';
import close from '../../assets/images/fechar.png';
import api from '../../services/api';
import Loading from '../Loading';
import ReplaceImageUrlService from '../../services/ReplaceImageUrlService';

function Menu() {
  const [menuActive, setMenuActive] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [logged, setLogged] = useState(false);
  const [userInfos, setUserInfos] = useState({});

  const [instituto, setInstituto] = useState({});
  const [loadFooter, setLoadFooter] = useState(true);

  const page = document.location.pathname.replace('/', '');

  async function destroySession() {
    sessionStorage.clear();
    setLogged(false);
  }

  async function userLogged(infos) {
    setUserInfos(infos.claims);
  }

  useEffect(() => {
    if (sessionStorage.getItem('userTofa') !== null) {
      const userValues = sessionStorage.getItem('userTofa');
      userLogged(jwtDecode(userValues));
      setLogged(true);
    }
    api.get(`instituto`).then(({ data }) => {
      setInstituto(data);
      setLoadFooter(false);
    });
  }, []);

  return (
    <header
      className='menu'
      data-aos='fade-right'
      data-aos-offset='100'
      data-aos-delay='50'
      data-aos-duration='700'
      data-aos-easing='ease-in-out'
    >
      <div className='menu_container'>
        <a href='/' className='menuHome'>
          <img
            src={ReplaceImageUrlService(logo)}
            alt='Tofa Instituto'
            title='Tofa Instituto'
            className='logo'
          />
        </a>
        <div className='menuOptions'>
          {/* <button */}
          {/*  type='button' */}
          {/*  className='searchHome' */}
          {/*  onClick={() => { */}
          {/*    setSearchActive(true); */}
          {/*  }} */}
          {/* > */}
          {/*  <img src={search} alt='Buscar' title='Buscar' className='search' /> */}
          {/* </button> */}
          <button
            type='button'
            className='menuCall'
            onClick={() => {
              setMenuActive(true);
            }}
          >
            <span>Menu</span>
            <img src={ReplaceImageUrlService(menu)} alt='Menu' title='Menu' className='menuIcon' />
          </button>
        </div>
      </div>
      <div className={menuActive ? 'main_menu active' : 'main_menu disabled'}>
        <header className='menu'>
          <div className='menu_container'>
            <a href='./' className='menuHome'>
              <img
                src={ReplaceImageUrlService(logo)}
                alt='Tofa Instituto'
                title='Tofa Instituto'
                className='logo'
              />
            </a>
            <div className='menuOptions'>
              {/* <button */}
              {/*  type='button' */}
              {/*  className='searchHome' */}
              {/*  onClick={() => { */}
              {/*    setSearchActive(true); */}
              {/*  }} */}
              {/* > */}
              {/*  <img */}
              {/*    src={search} */}
              {/*    alt='Buscar' */}
              {/*    title='Buscar' */}
              {/*    className='search' */}
              {/*  /> */}
              {/* </button> */}
              <button
                type='button'
                className='menuCall'
                onClick={() => {
                  setMenuActive(false);
                }}
              >
                <span>Fechar</span>
                <img
                  src={ReplaceImageUrlService(close)}
                  alt='Fechar'
                  title='Fechar'
                  className='menuIcon fechar'
                />
              </button>
            </div>
          </div>
        </header>
        <section>
          <div className='container flexRow'>
            <nav className='menu-links'>
              <a href='/'>Home</a>
              <a href='/sobre-nos' className={page === 'sobre-nos' && 'active'}>
                Sobre Nós
              </a>
              <a
                href='/experiencia-formacoes'
                className={page === 'experiencia-formacoes' && 'active'}
              >
                Experiências e Formações
              </a>
              {/* <a href='/clinica'>A Clínica</a> */}
              <a href='/servicos' className={page === 'servicos' && 'active'}>
                Serviços
              </a>
              {/* <a */}
              {/*  href='/#tratamentos' */}
              {/*  className={page === 'servicos' && 'active'} */}
              {/* > */}
              {/*  Tratamentos */}
              {/* </a> */}
              <a href='/cursos' className={page === 'cursos' && 'active'}>
                Cursos
              </a>
              <a href='/agenda' className={page === 'agenda' && 'active'}>
                Agenda
              </a>
              <a href='/blog' className={page === 'blog' && 'active'}>
                Blog
              </a>
              <a href='/live' className={page === 'live' && 'active'}>
                Lives
              </a>
              <a href='/contato' className={page === 'contato' && 'active'}>
                Contato
              </a>
            </nav>
            {loadFooter ? (
              <Loading />
            ) : (
              <footer className='menu-footer'>
                <div className='box_logout'>
                  {logged ? (
                    <div className='logged'>
                      Seja bem vindo, <strong>{userInfos.nome}</strong>
                      <a href='/dashboard' className='logout'>
                        Acesse seu dashboard
                      </a>
                      <a
                        href='#!'
                        className='logout'
                        onClick={() => {
                          destroySession();
                        }}
                      >
                        SAIR
                      </a>
                    </div>
                  ) : (
                    <a href='/dashboard' className='logout'>
                      Faça seu login
                    </a>
                  )}
                </div>
                <div className='box-contact'>
                  <strong>Contato</strong>
                  <a href={`tel:0${instituto.telefone.replace(/\D/g, '')}`}>
                    {instituto.telefone}
                  </a>
                  <a href={`tel:0${instituto.whatsapp.replace(/\D/g, '')}`}>
                    {instituto.whatsapp} (whatsapp)
                  </a>
                  <strong>Endereço</strong>
                  <p>{instituto.endereco}</p>
                </div>
                <div className='box-contact'>
                  <strong>E-mail</strong>
                  <a href={`mailto:${instituto.email}`} className='email'>
                    {instituto.email}
                  </a>

                  <div className='box_logout mobile'>
                    {logged ? (
                      <div className='logged'>
                        <a href='/dashboard' className='logout'>
                          Painel
                        </a>
                        <a
                          href='#!'
                          className='logout'
                          onClick={() => {
                            destroySession();
                          }}
                        >
                          SAIR
                        </a>
                      </div>
                    ) : (
                      <a href='/dashboard' className='logout'>
                        Faça seu login
                      </a>
                    )}
                  </div>
                </div>
              </footer>
            )}
          </div>
        </section>
        <div className='social'>
          <div className='copyright'>
            Todos os direitos reservados © {new Date().getFullYear()} -
            Instituto Tofa
          </div>
          <div>
            <a
              href={process.env.REACT_APP_FACEBOOK}
              rel='noreferrer'
              target='_blank'
            >
              Fb
            </a>
            <a
              href={process.env.REACT_APP_INSTAGRAM}
              rel='noreferrer'
              target='_blank'
            >
              In
            </a>
            <a
              href={process.env.REACT_APP_YOUTUBE}
              rel='noreferrer'
              target='_blank'
            >
              Ytb
            </a>
          </div>
        </div>
      </div>
      <div
        className={searchActive ? 'search_menu active' : 'search_menu disabled'}
      >
        <div className='container'>
          <header className='menu menu_search'>
            <button
              type='button'
              className='menuCall'
              onClick={() => {
                setSearchActive(false);
              }}
            >
              <span>Fechar</span>
              <img
                src={ReplaceImageUrlService(close)}
                alt='Fechar'
                title='Fechar'
                className='menuIcon fechar'
              />
            </button>
          </header>
        </div>
        <div className='container'>
          <form action='' className='search-home'>
            <p>Faça sua</p>
            <strong className='newstitle'>Pesquisa</strong>
            <div className='baseForm'>
              <div className='control'>
                <label htmlFor='search'>
                  <input type='text' name='search' id='search' />
                </label>
              </div>
              <div className='control'>
                <input type='submit' value='Buscar' />
              </div>
            </div>
          </form>
        </div>
      </div>
    </header>
  );
}

export default Menu;
