import tratamento from '../../assets/images/tratamento.jpg';
import ReplaceImageUrlService from '../../services/ReplaceImageUrlService';

function Services({ index, image, title, description }) {
  return (
    <div className='treatmentBox'>
      <img
        src={ReplaceImageUrlService(image !== null ? image : tratamento)}
        alt={title}
        title={title}
      />
      <strong>
        <span>0{index}</span>
        {title}
      </strong>
      <p>{description}</p>
    </div>
  );
}

export default Services;
