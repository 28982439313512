import React from 'react';
import avatar from '../../assets/images/avatar.png';
import ReplaceImageUrlService from '../../services/ReplaceImageUrlService';

function CoursePayment({
  slug,
  professor,
  professorImage,
  type,
  image,
  title,
  description,
  price,
  externo,
  linkExterno,
  paused
}) {
  const returnPrice = thisPrice => {
    // result % 1 !== 0 ? result.toFixed(2) : result;
    if (thisPrice % 1 !== 0) {
      return (
        <>
          {`R$ ${thisPrice}`}
          <sup>,00</sup>
        </>
      );
    }

    const stringifiedPrice = thisPrice.toFixed(2);

    return (
      <>
        {`R$ ${stringifiedPrice.substring(0, stringifiedPrice.length - 3)}`}
        <sup>
          ,
          {stringifiedPrice.substring(
            stringifiedPrice.length - 2,
            stringifiedPrice.length
          )}
        </sup>
      </>
    );
  };

  return (
    <div className='course_column_payment'>
      <div className='course course_payment'>
        <div className='course_image'>
          <picture>
            <img src={ReplaceImageUrlService(image)} alt={title} title={title} />
            <span>Curso {type}</span>
          </picture>
        </div>
        <div className='course_details sell'>
          <p className='course_details_price curso'>
            <span>Valor:</span>
            <strong>{returnPrice(price)}</strong>
          </p>
          {/* eslint-disable-next-line */}
          {paused ? (
            <a
              href={linkExterno}
              className='course_details_button'
              target='_blank'
              rel='noopener noreferrer'
            >
              Em breve
            </a>
          ) : externo ? (
            <a
              href={linkExterno}
              className='course_details_button'
              target='_blank'
              rel='noopener noreferrer'
            >
              Comprar
            </a>
          ) : (
            <a
              href={`/cursos/${slug}/pagamento`}
              className='course_details_button'
            >
              Comprar
            </a>
          )}
        </div>
      </div>
      <div className='course_professor'>
        <img src={ReplaceImageUrlService(professorImage)} alt={professor} />
        <p className='course_professor_name'>{professor}</p>
        <p className='course_professor_description'>{description}</p>
      </div>
    </div>
  );
}

export default CoursePayment;
