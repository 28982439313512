import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import BannersPages from '../../components/Sliders/live';

import bgMobile from '../../assets/images/bg_live_mobile.jpg';
import bg from '../../assets/images/bg_live.jpg';
import play from '../../assets/images/play.png';
import Loading from '../../components/Loading';

function Live() {
  const [liveActive, setLiveActive] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setLiveActive(false);
      setLoading(false);
    }
    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <main>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Helmet>
            <title>Live - {process.env.REACT_APP_TITLE}</title>
          </Helmet>
          <BannersPages
            {...{
              bgMobile,
              bg,
              text: 'Live Tofa Instituto',
              title: 'Twitch.TV',
              subtitle: ''
            }}
          />
          <section className='livePage'>
            <div className='statusLive'>
              <div className='displayStatus'>
                <span className={`status ${liveActive}`} />
                <p>{liveActive ? 'Estamos ao vivo' : 'Transmissão offline'}</p>
              </div>
            </div>
            <div className='videoLive'>
              <img
                src={play}
                className='play'
                alt='Assista o vídeo'
                title='Assista o vídeo'
              />
            </div>
            <button className='link twitch' type='button'>
              INTERAGIR NO CHAT PELA TWITCH.TV
            </button>
          </section>
        </>
      )}
    </main>
  );
}

export default Live;
