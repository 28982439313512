import AliceCarousel from 'react-alice-carousel';
import partner from '../../assets/images/parceiros.png';
import ReplaceImageUrlService from '../../services/ReplaceImageUrlService';

function Partners({ partners }) {
  // const handleDragStart = e => e.preventDefault();

  const items =
    partners &&
    partners.map(part => (
      <div className='partnersBoxes' key={`partners_${part.id}`}>
        <figure className='partnersImages'>
          <img src={ReplaceImageUrlService(part.logo.url)} alt='Parceiro' />
          {/* <strong>Parceiro</strong> */}
        </figure>
      </div>
    ));

  return (
    <AliceCarousel
      autoPlay
      autoPlayInterval={5000}
      items={items}
      infinite
      disableButtonsControls
      disableDotsControls
      responsive={{
        0: {
          items: 4
        },
        767: {
          items: 4
        },
        1024: {
          items: 4
        }
      }}
    />
  );
}

export default Partners;
