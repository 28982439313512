function WeAre({ link, title, description }) {
  return (
    <div className='weAreBox'>
      <strong>{title || 'Sobre'}</strong>
      <p
        data-aos='fade-up'
        data-aos-offset='200'
        data-aos-delay='80'
        data-aos-duration='700'
        data-aos-easing='ease-in-out'
      >
        {description}
      </p>
      {link && (
        <a
          href='./'
          className='more dark'
          data-aos='fade-up'
          data-aos-offset='200'
          data-aos-delay='110'
          data-aos-duration='700'
          data-aos-easing='ease-in-out'
        >
          Saiba mais
        </a>
      )}
    </div>
  );
}

export default WeAre;
