import React, { useEffect, useState } from 'react';

import { Link, useHistory } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

import { parseISO } from 'date-fns';

import { Helmet } from 'react-helmet';
import Loading from '../../components/Loading';
import BannersPages from '../../components/Sliders/pages';

import arrow from '../../assets/images/arrow_pagination.png';
import facebook from '../../assets/images/share_facebook.png';
import instagram from '../../assets/images/share_instagram.png';
import whatsapp from '../../assets/images/share_whatsapp.png';
import bgMobile from '../../assets/images/bg_blog_mobile.jpg';
import bg from '../../assets/images/bg_blog_home.jpg';

import api from '../../services/api';

function Blog({ match }) {
  const [article, setArticle] = useState({});
  const [nextArticleSlug, setNextArticleSlug] = useState('');
  const [lastArticleSlug, setLastArticleSlug] = useState('');
  const [loading, setLoading] = useState(true);
  const artigoSlug = match.params.slug;
  const history = useHistory();

  useEffect(() => {
    api.get(`artigos?slug=${match.params.slug}`).then(({ data: rawData }) => {
      if (!rawData || rawData.length !== 1) return history.push('/blog');

      const data = rawData[0];

      setArticle(data);

      setLoading(false);
      window.scrollTo(0, 0);

      api
        .get(`artigos?id_gt=${data.id}&_limit=1&_sort=id:ASC`)
        .then(response => {
          setNextArticleSlug(
            response.data.length >= 1 ? response.data[0].slug : undefined
          );
        });
      api
        .get(`artigos?id_lt=${data.id}&_limit=1&_sort=id:DESC`)
        .then(response => {
          setLastArticleSlug(
            response.data.length >= 1 ? response.data[0].slug : undefined
          );
        });

      return true;
    });
  }, [artigoSlug]);

  return (
    <main>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Helmet>
            <title>
              {article.titulo} | Blog - {process.env.REACT_APP_TITLE}
            </title>
          </Helmet>
          <BannersPages
            {...{
              bgMobile: article.bannerMobile
                ? article.bannerMobile.url
                : bgMobile,
              bg: article.bannerDesktop ? article.bannerDesktop.url : bg,
              text: article.textoBackground,
              title: article.titulo,
              subtitle: ''
            }}
          />
          <section className='internalPage share'>
            <div className='container'>
              <div className='internalPost'>
                <span>
                  <strong>
                    Autor:{' '}
                    <span>
                      {article.doutor ? article.doutor.nome : article.autor}
                    </span>
                  </strong>
                  <p>
                    {Intl.DateTimeFormat('pt-BR', {
                      day: '2-digit',
                      month: 'long',
                      year: 'numeric'
                    }).format(parseISO(article.created_at))}
                  </p>
                </span>
                <strong>{article.tituloLongo}</strong>
                <p>
                  <ReactMarkdown source={article.conteudo} />
                </p>
              </div>
            </div>
            <div className='container'>
              <nav className='pagination'>
                {lastArticleSlug ? (
                  <Link to={`/blog/${lastArticleSlug}`}>
                    <img src={arrow} alt='Artigo anterior' />
                    <span>Artigo anterior</span>
                  </Link>
                ) : (
                  <div />
                )}
                {nextArticleSlug ? (
                  <Link to={`/blog/${nextArticleSlug}`}>
                    <span>Próximo artigo</span>
                    <img src={arrow} className='next' alt='Próximo artigo' />
                  </Link>
                ) : (
                  <div />
                )}
              </nav>
            </div>
            <nav className='sharing'>
              <strong>Compartilhe esse artigo</strong>
              <div>
                <a
                  href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
                  rel='noreferrer'
                  target='_blank'
                >
                  <img src={facebook} alt='Facebook' />
                </a>
                <a
                  href={`https://www.instagram.com/?url=${window.location.href}`}
                  rel='noreferrer'
                  target='_blank'
                >
                  <img src={instagram} alt='Instagram' />
                </a>
                <a
                  href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
                    window.location.href
                  )}`}
                  rel='noreferrer'
                  target='_blank'
                >
                  <img src={whatsapp} alt='Whatsapp' />
                </a>
              </div>
            </nav>
          </section>
        </>
      )}
    </main>
  );
}

export default Blog;
