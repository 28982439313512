import React from 'react';
import { Link } from 'react-router-dom';

import AliceCarousel from 'react-alice-carousel';
import BoxTreatment from '../Boxes/treatments';

function BannersHome({ treatments }) {
  // const handleDragStart = e => e.preventDefault();
  const items = treatments.map(
    ({ id, titulo, descricaoCurta, imagem, slug }) => (
      <div key={id} className='treatmentSlider'>
        <BoxTreatment
          {...{
            index: id,
            image: imagem ? imagem.url : '',
            title: titulo,
            description: descricaoCurta
          }}
        />
        <Link to={`/servicos/${slug}`} className='more'>
          Saiba mais
        </Link>
      </div>
    )
  );
  return (
    <AliceCarousel
      autoPlayInterval={5000}
      items={items}
      animationType='fade'
      // autoPlay
      infinite
      disableDotsControls
      responsive={{
        0: {
          items: 1
        },
        1024: {
          items: 3
        }
      }}
    />
  );
}

export default BannersHome;
