import video from '../../assets/images/video.png';
import play from '../../assets/images/play.png';
import ReplaceImageUrlService from '../../services/ReplaceImageUrlService';

function Videos({ image, title, description, link }) {
  return (
    <div className='videoBox'>
      <div>
        <img src={ReplaceImageUrlService(image !== null ? image : video)} alt={title} title={title} />
        <strong>{title}</strong>
        <p>{description}</p>
      </div>
      <a href={link} rel='noreferrer' target='_blank' className='videoPlay'>
        <img src={ReplaceImageUrlService(play)} alt='Assista o vídeo' />
      </a>
    </div>
  );
}

export default Videos;
