function StrokeTitle({ divClass, letter, letterClass, title, subtitle }) {
  return (
    <div
      className={`strokeTitle ${divClass}`}
      data-aos='fade-up'
      data-aos-offset='200'
      data-aos-delay='50'
      data-aos-duration='700'
      data-aos-easing='ease-in-out'
    >
      <span className={letterClass}>{letter}</span>
      <p>{title}</p>
      <strong>{subtitle}</strong>
    </div>
  );
}

export default StrokeTitle;
