function CourseDetails({ title, description }) {
  return (
    <div className='courseDetailsBox'>
      <strong>{title}</strong>
      <p
        data-aos='fade-up'
        data-aos-offset='200'
        data-aos-delay='80'
        data-aos-duration='700'
        data-aos-easing='ease-in-out'
      >
        {description}
      </p>
    </div>
  );
}

export default CourseDetails;
