import React, { useState, useEffect } from 'react';
import api from '../../services/api';

import StrokeTitle from '../Titles/stroke';
import NewsSlider from '../Sliders/news';

function ArticlesAndNews({ articles }) {
  const arrayPages = [
    '',
    'sobre-nos',
    'laboratorio',
    'clinica',
    'servicos',
    'servicos/implante-dentario',
    'servicos/lente-de-contato',
    'servicos/clareamento-dental'
  ];
  const page = document.location.pathname.replace('/', '');
  const [selfArticles, setSelfArticles] = useState([]);

  useEffect(() => {
    if (!articles || articles.length < 1) {
      api.get('artigos?_sort=id:ASC').then(({ data }) => {
        setSelfArticles(data);
      });
    }
  }, []);

  return (
    <section
      className={`content ${arrayPages.includes(page) ? 'news' : ''} ${
        page === 'servicos' ? 'interna' : ''
      }`}
    >
      <StrokeTitle
        {...{
          divClass: 'novidadesHome',
          letter: 'N',
          title: 'Artigos e',
          subtitle: 'Novidades'
        }}
      />
      <NewsSlider
        {...{
          artigos: articles && articles.length >= 1 ? articles : selfArticles
        }}
      />
    </section>
  );
}

export default ArticlesAndNews;
