import { useEffect, useState } from 'react';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';
import AliceCarousel from 'react-alice-carousel';

import { Helmet } from 'react-helmet';
import api from '../../services/api';
import BannersPages from '../../components/Sliders/pages';
import CenterTitle from '../../components/Titles/center';
import BoxWeAre from '../../components/Boxes/weAre';
import About from '../../components/Boxes/about';
// import Instagram from '../../components/Boxes/instagram';
import StrokeTitleCenter from '../../components/Titles/strokeCenter';
import ArticlesAndNews from '../../components/Boxes/articlesAndNews';

import bgMobile from '../../assets/images/bg_sobrenos_mobile.jpg';
import bg from '../../assets/images/bg_sobrenos.jpg';
import icone02 from '../../assets/images/icone_servicos02.png';
import icone03 from '../../assets/images/icone_servicos03.png';
import Loading from '../../components/Loading';
import ReplaceImageUrlService from '../../services/ReplaceImageUrlService';

function Clinic() {
  const [loading, setLoading] = useState(true);
  const [clinicData, setClinicData] = useState();
  const [clinicImages, setClinicImages] = useState([]);

  useEffect(() => {
    api.get('clinica').then(({ data }) => {
      setClinicData(data);

      const receivedMessages = [];

      let imageIteration = 1;
      let comparassion = 1;

      for (let i = 1; i <= 8; i++) {
        switch (imageIteration) {
          case 1:
            if (data.imagensMasonry[`imagemVertical${comparassion}`])
              receivedMessages.push(
                data.imagensMasonry[`imagemVertical${comparassion}`]
              );
            imageIteration += 1;

            break;
          case 2:
            if (data.imagensMasonry[`imagemHorizontal${comparassion}`])
              receivedMessages.push(
                data.imagensMasonry[`imagemHorizontal${comparassion}`]
              );
            imageIteration += 1;

            break;
          case 3:
            if (data.imagensMasonry[`imagemHorizontal${comparassion + 1}`])
              receivedMessages.push(
                data.imagensMasonry[`imagemHorizontal${comparassion + 1}`]
              );
            imageIteration += 1;

            break;
          case 4:
            if (data.imagensMasonry[`imagemVertical${comparassion + 1}`])
              receivedMessages.push(
                data.imagensMasonry[`imagemVertical${comparassion + 1}`]
              );
            imageIteration = 1;
            comparassion += 2;

            break;
          default:
            break;
        }
      }

      setClinicImages(receivedMessages);

      setLoading(false);
      window.scrollTo(0, 0);
    });
  }, []);

  const arrangeImages = unorganizedImages => {
    if (!unorganizedImages) return [];

    const arrangedImages = [];
    let iterationArray = [];
    let iteration = 1;

    unorganizedImages.forEach(iterationImage => {
      if (iteration <= 4) {
        iterationArray.push(iterationImage);
        iteration += 1;
      } else {
        arrangedImages.push(iterationArray);
        iteration = 2;
        iterationArray = [iterationImage];
      }
    });

    arrangedImages.push(iterationArray);

    return arrangedImages;
  };

  const items = arrangeImages(clinicImages).map(imageGrid => (
    <div className='grid-container'>
      {imageGrid.map((iterationImage, index) => (
        <div className={`image0${index + 1}`}>
          <img src={ReplaceImageUrlService(iterationImage.url)} alt={`sobre0${index + 1}`} />
        </div>
      ))}
    </div>
  ));

  return (
    <main>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Helmet>
            <title>A Clínica - {process.env.REACT_APP_TITLE}</title>
          </Helmet>
          <BannersPages
            {...{
              bgMobile,
              bg,
              text: 'Clinica',
              title: 'Clínica',
              subtitle: ''
            }}
          />
          <section className='internalPage clinica'>
            <CenterTitle
              {...{
                text: 'Clinica',
                title: 'Conheça nossa',
                subtitle: 'Clínica'
              }}
            />
            <div className='container flexRow noTitles'>
              <BoxWeAre
                {...{
                  title: clinicData.conteudoTexto,
                  description: clinicData.paragrafo1
                }}
              />
              <BoxWeAre
                {...{
                  description: clinicData.paragrafo2
                }}
              />
              <BoxWeAre
                {...{
                  description: clinicData.paragrafo3
                }}
              />
            </div>
            <div className='container'>
              <div className='slider_aboutUs'>
                <AliceCarousel
                  autoPlayInterval={5000}
                  items={items}
                  animationType='fade'
                  // autoPlay
                  infinite
                  disableDotsControls
                />
              </div>
            </div>
          </section>
          <section className='clinica__title laboratory'>
            <div className='no_text'>
              <StrokeTitleCenter
                {...{
                  divClass: 'minorTitle',
                  letter: clinicData.subtitulo.substring(0, 1),
                  letterClass: '',
                  title: clinicData.subtitulo,
                  subtitle: clinicData.titulo
                }}
              />
              {clinicData.textoComTopico.map(textoComTopico => (
                <div className='content clinica'>
                  <div className='container'>
                    <div className='content__text'>
                      <p>{textoComTopico.texto}</p>
                    </div>
                  </div>
                  <div className='content__text'>
                    <div className='container'>
                      <Accordion>
                        {textoComTopico.topicoDoTexto.map(topicoDoTexto => (
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                {topicoDoTexto.topico}
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>{topicoDoTexto.texto}</p>
                            </AccordionItemPanel>
                          </AccordionItem>
                        ))}
                      </Accordion>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className='content about_options_bg'>
              <div className='container'>
                <div className='content__text'>
                  {clinicData.topicoComImagem.map(topicoComImagem => (
                    <About
                      {...{
                        title: topicoComImagem.titulo,
                        description: topicoComImagem.descricao
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
          </section>
          <section className='internalPage'>
            <div className='container flexRow'>
              <div>
                <CenterTitle
                  {...{
                    divClass: 'leftText',
                    text: 'Entre em contato',
                    title: 'Entre em contato conosco',
                    subtitle: 'Através do formulário'
                  }}
                />
                <p className='content__text black'>
                  O Instituto Tofaneto é a junção de bagagem e conhecimento no
                  ramo da Odontologia, portanto, queremos uma experiência
                  digital, para você traze-la mais perto de sua clínica. Entre
                  em contato conosco e descubra essa nova realidade.
                </p>
              </div>
              <form action='' className='contact_services'>
                <div className='baseForm'>
                  <div className='control'>
                    <label htmlFor='nome'>
                      <input
                        type='text'
                        name='nome'
                        id='nome'
                        placeholder='Insira seu nome'
                      />
                    </label>
                  </div>
                  <div className='control'>
                    <label htmlFor='telefone'>
                      <input
                        type='tel'
                        name='telefone'
                        id='telefone'
                        placeholder='Insira seu telefone'
                      />
                    </label>
                  </div>
                  <div className='control'>
                    <label htmlFor='email'>
                      <input
                        type='email'
                        name='email'
                        id='email'
                        placeholder='Insira seu e-mail'
                      />
                    </label>
                  </div>
                  <div className='control'>
                    <label htmlFor='email'>
                      <textarea placeholder='Escreva sua mensagem' />
                    </label>
                  </div>
                  <div className='control'>
                    <input type='submit' value='Assinar' />
                  </div>
                </div>
              </form>
            </div>
          </section>
          <ArticlesAndNews />
          {/* <Instagram /> */}
        </>
      )}
    </main>
  );
}

export default Clinic;
