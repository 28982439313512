import React, { useState, useEffect } from 'react';

import { Helmet } from 'react-helmet';
import api from '../../services/api';

import Loading from '../../components/Loading';
import BannersPages from '../../components/Sliders/pages';

import bgMobile from '../../assets/images/bg_blog_mobile.jpg';
import bg from '../../assets/images/bg_blog_home.jpg';
import BlogBoxes from '../../components/Boxes/blog';

function Blog() {
  const [artigos, setArtigos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api.get('artigos?_sort=id:ASC').then(({ data }) => {
      setArtigos(data);

      setLoading(false);
    });
  }, []);

  return (
    <main>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Helmet>
            <title>Blog - {process.env.REACT_APP_TITLE}</title>
          </Helmet>
          <BannersPages
            {...{
              bgMobile,
              bg,
              text: 'Nosso Blog',
              title: 'Blog',
              subtitle: ''
            }}
          />
          <section className='internalPageBlog'>
            <BlogBoxes {...{ artigos }} />
          </section>
        </>
      )}
    </main>
  );
}

export default Blog;
