import { Helmet } from 'react-helmet';
import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { toast } from 'react-toastify';

import jwtDecode from 'jwt-decode';
import BannersPages from '../../components/Sliders/pages';
import Loading from '../../components/Loading';

import bgMobile from '../../assets/images/bg_blog_mobile.jpg';
import bg from '../../assets/images/bg_pagamento.jpg';
import imagePreview from '../../assets/images/bg_blog.jpg';
import api from '../../services/api';
import ErrorMessageService from '../../services/ErrorMessageService';
import ReplaceImageUrlService from '../../services/ReplaceImageUrlService';

function Dashboard() {
  const [courseData, setCourseData] = useState({});
  const [infos, setInfos] = useState({});
  const [loading, setLoading] = useState(true);
  const [logged, setLogged] = useState(false);

  async function destroySession() {
    sessionStorage.clear();
    setLogged(false);
  }

  function login(e) {
    e.preventDefault();

    const postData = {
      email: e.target.emailLogin.value,
      senha: e.target.passwordLogin.value
    };
    // eslint-disable-next-line
    // console.log(postData);
    api
      .post(`sessions`, postData)
      .then(({ data }) => {
        sessionStorage.setItem('userTofa', data.authorizationKey);
        setInfos(jwtDecode(data.authorizationKey));
        setLogged(true);

        api
          .get(`pedidos`, {
            headers: {
              tofaauthorization: `Bearer ${sessionStorage.getItem('userTofa')}`
            }
          })
          .then(({ data: responseData }) => {
            setCourseData(responseData);
          });
      })
      .catch(error => {
        ErrorMessageService(error);
      });
  }

  async function loadInfos() {
    if (sessionStorage.getItem('userTofa') !== null) {
      const userValues = sessionStorage.getItem('userTofa');

      api
        .get(`pedidos`, {
          headers: {
            tofaauthorization: `Bearer ${sessionStorage.getItem('userTofa')}`
          }
        })
        // .post(`pedidos?tofaauthorization=${sessionStorage.getItem('userTofa')}`)
        .then(({ data }) => {
          setCourseData(data);
        });
      setLogged(true);
    }
  }

  useEffect(() => {
    loadInfos();
    setLoading(false);
  }, []);

  return (
    <main>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Helmet>
            <title>Dashboard - {process.env.REACT_APP_TITLE}</title>
          </Helmet>
          <BannersPages
            {...{
              bgMobile,
              bg,
              text: 'Dashboard',
              title: 'Dashboard',
              subtitle: ''
            }}
          />
          <section className='internalPage carrinho'>
            <section className={`contact_services ${!logged || 'hide'}`}>
              <div>
                <form action='' className='contact_services' onSubmit={login}>
                  <div className='cartSteps'>
                    <strong>Já é cadastrado?</strong>
                    <p>Realize seu login</p>
                    <div className='baseForm'>
                      <div className='control'>
                        <label htmlFor='emailLogin'>
                          <input
                            type='email'
                            name='emailLogin'
                            id='emailLogin'
                            placeholder='E-mail'
                          />
                        </label>
                      </div>
                      <div className='control'>
                        <label htmlFor='passwordLogin'>
                          <input
                            type='password'
                            name='passwordLogin'
                            id='passwordLogin'
                            placeholder='Senha'
                          />
                        </label>
                      </div>
                      <div className='control'>
                        <input type='submit' value='Logar' />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </section>
            <section className={`contact_services ${logged || 'hide'}`}>
              <div className='box_logout'>
                <a
                  href='#!'
                  className='logout'
                  onClick={() => {
                    destroySession();
                  }}
                >
                  SAIR
                </a>
              </div>
              <div className='container pagamento flexColumn'>
                {courseData.length > 0 ? (
                  courseData.map((course, index) => (
                    <div key={`cursos_${index + 1}`}>
                      <div className='cartSteps'>
                        <strong>
                          <span className='number'>{index + 1}</span>
                          {course.curso.titulo}
                        </strong>
                        <div>
                          <img
                            src={ReplaceImageUrlService(`${
                              course && course.curso && course.curso.imagem
                                ? course.curso.imagem.url
                                : imagePreview
                            }`)}
                            alt={course.curso.titulo}
                          />
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <strong>Descrição:</strong>
                                </td>
                                <td>{course.curso.descricao}</td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Valor:</strong>
                                </td>
                                <td>
                                  {course.valorPago.toLocaleString('pt-br', {
                                    style: 'currency',
                                    currency: 'BRL'
                                  })}{' '}
                                  (pago via{' '}
                                  {course.boleto ? 'boleto' : 'cartão'} em{' '}
                                  {course.created_at &&
                                    format(
                                      new Date(course.created_at),
                                      'dd/MM/yyyy'
                                    )}
                                  )
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Modelo:</strong>
                                </td>
                                <td>
                                  <strong>
                                    <span>
                                      {course.curso.presencial
                                        ? 'Presencial'
                                        : 'Online'}
                                    </span>
                                  </strong>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>
                    Você ainda não adquiriu cursos, ou estes estão em processo
                    de confirmação.
                  </p>
                )}
              </div>
            </section>
          </section>
        </>
      )}
    </main>
  );
}

export default Dashboard;
