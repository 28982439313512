import React, { useEffect, useState } from 'react';

// import {
//   Accordion,
//   AccordionItem,
//   AccordionItemHeading,
//   AccordionItemButton,
//   AccordionItemPanel
// } from 'react-accessible-accordion';

import AliceCarousel from 'react-alice-carousel';
import { Helmet } from 'react-helmet';
import api from '../../services/api';

import BannersPages from '../../components/Sliders/pages';
import CenterTitle from '../../components/Titles/center';
import BoxWeAre from '../../components/Boxes/weAre';
import About from '../../components/Boxes/about';
// import Instagram from '../../components/Boxes/instagram';
import StrokeTitleCenter from '../../components/Titles/strokeCenter';
import ArticlesAndNews from '../../components/Boxes/articlesAndNews';
import Cases from '../../components/Boxes/cases';
import Loading from '../../components/Loading';

import bgMobile from '../../assets/images/bg_sobrenos_mobile.jpg';
import bg from '../../assets/images/bg_sobrenos.jpg';
import thiago from '../../assets/images/thiago.png';
import icone02 from '../../assets/images/icone_servicos02.png';
import icone03 from '../../assets/images/icone_servicos03.png';
import notebook from '../../assets/images/notebook.png';

import sobre01 from '../../assets/images/sobre01.png';
import sobre02 from '../../assets/images/sobre02.png';
import sobre03 from '../../assets/images/sobre03.png';
import sobre04 from '../../assets/images/sobre04.png';
import ReplaceImageUrlService from '../../services/ReplaceImageUrlService';

function AboutUs() {
  const [ourPlaceText, setOurPlaceText] = useState([]);
  const [ourPlaceImages, setOurPlaceImages] = useState([]);
  const [aboutDoctor, setAboutDoctor] = useState('');
  const [courseDescription, setCourseDescription] = useState('');
  const [courseLink, setCourseLink] = useState('');
  const [ourCasesText, setOurCasesText] = useState('');
  const [ourCasesImages, setOurCasesImages] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [topicsAboutDoctor, setTopicsAboutDoctor] = useState([]);
  const [topicsAboutCourse, setTopicsAboutCourse] = useState([]);
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);

  const changeImgHost = ({ currentImgUrl, targetHost, separatorIdentifier }) =>
    currentImgUrl.replace(
      currentImgUrl.substring(
        0,
        currentImgUrl.indexOf(separatorIdentifier) + separatorIdentifier.length
      ),
      targetHost
    );

  useEffect(() => {
    api.get('sobre-nos').then(({ data }) => {
      setOurPlaceText([
        data.nossoEspacoParagrafo1,
        data.nossoEspacoParagrafo2,
        data.nossoEspacoParagrafo3
      ]);
      setAboutDoctor(data.sobreODoutor);
      setCourseDescription(data.cursoDescricao);
      setCourseLink(data.cursoLink);
      setOurCasesText(data.nossosCasesTexto);
      setOurCasesImages(data.nossosCasesImagens);
      setQuestions(data.perguntas);
      setTopicsAboutDoctor(data.topicosSobreODoutor);
      setTopicsAboutCourse(data.topicosSobreOCurso);
      setLoading(false);

      const receivedMessages = [];

      let imageIteration = 1;
      let comparassion = 1;

      for (let i = 1; i <= 8; i++) {
        switch (imageIteration) {
          case 1:
            if (data.imagensMasonry[`imagemVertical${comparassion}`])
              receivedMessages.push(
                data.imagensMasonry[`imagemVertical${comparassion}`]
              );
            imageIteration += 1;

            break;
          case 2:
            if (data.imagensMasonry[`imagemHorizontal${comparassion}`])
              receivedMessages.push(
                data.imagensMasonry[`imagemHorizontal${comparassion}`]
              );
            imageIteration += 1;

            break;
          case 3:
            if (data.imagensMasonry[`imagemHorizontal${comparassion + 1}`])
              receivedMessages.push(
                data.imagensMasonry[`imagemHorizontal${comparassion + 1}`]
              );
            imageIteration += 1;

            break;
          case 4:
            if (data.imagensMasonry[`imagemVertical${comparassion + 1}`])
              receivedMessages.push(
                data.imagensMasonry[`imagemVertical${comparassion + 1}`]
              );
            imageIteration = 1;
            comparassion += 2;

            break;
          default:
            break;
        }
      }

      setOurPlaceImages(receivedMessages);
    });

    api.get('artigos?_sort=id:ASC').then(({ data }) => {
      setArticles(data);
    });
  }, []);

  const arrangeImages = unorganizedImages => {
    if (!unorganizedImages) return [];

    const arrangedImages = [];
    let iterationArray = [];
    let iteration = 1;

    unorganizedImages.forEach(iterationImage => {
      if (iteration <= 4) {
        iterationArray.push(iterationImage);
        iteration += 1;
      } else {
        arrangedImages.push(iterationArray);
        iteration = 2;
        iterationArray = [iterationImage];
      }
    });

    arrangedImages.push(iterationArray);

    return arrangedImages;
  };

  const items = arrangeImages(ourPlaceImages).map(imageGrid => (
    <div className='grid-container'>
      {imageGrid.map((iterationImage, index) => (
        <div className={`image0${index + 1}`}>
          <img src={ReplaceImageUrlService(iterationImage.url)} alt={`sobre0${index + 1}`} />
        </div>
      ))}
    </div>
  ));

  return (
    <main className='internal'>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Helmet>
            <title>Sobre nós - {process.env.REACT_APP_TITLE}</title>
          </Helmet>
          <BannersPages
            {...{
              bgMobile,
              bg,
              text: 'Sobre Nos',
              title: 'Sobre',
              subtitle: 'Nós'
            }}
          />
          <section className='internalPage'>
            <span className='patternLeft' />
            <span className='patternRight' />
            <div className='container'>
              <CenterTitle
                {...{
                  divClass: 'noMargin',
                  text: 'Tofa',
                  title: 'Conheça o',
                  subtitle: 'Tofa Instituto'
                }}
              />
            </div>
            <div className='container flexRow baseline noTitles'>
              {ourPlaceText.map((paragraph, index) => (
                <BoxWeAre
                  key={index}
                  {...{
                    title: 'Nosso Espaço',
                    description: paragraph
                  }}
                />
              ))}
            </div>
            <div className='container'>
              <div className='slider_aboutUs'>
                <AliceCarousel
                  autoPlayInterval={5000}
                  items={items}
                  animationType='fade'
                  // autoPlay
                  infinite
                  disableDotsControls
                />
              </div>
            </div>
          </section>
          <section className='sobre-doutor'>
            <div className='container'>
              <StrokeTitleCenter
                {...{
                  divClass: 'minorTitle',
                  letter: 'S',
                  letterClass: '',
                  title: 'Sobre o',
                  subtitle: 'Dr. Thiago Tofaneto'
                }}
              />
              <section
                className='sobre-thiago'
                data-aos='fade-up'
                data-aos-offset='200'
                data-aos-delay='50'
                data-aos-duration='200'
                data-aos-easing='ease-in-out'
              >
                <div className='content sobre stripes'>
                  <img
                    src={ReplaceImageUrlService(thiago)}
                    className='thiago'
                    alt='Dr. Thiago Tofaneto'
                    title='Dr. Thiago Tofaneto'
                  />
                  <div className='content__text'>
                    <span>Thiago Tofaneto</span>
                  </div>
                </div>
                <div className='content sobre'>
                  <div className='content__text'>
                    <p>{aboutDoctor}</p>
                  </div>
                  {/* <div className='content__text'> */}
                  {/*  <Accordion> */}
                  {/*    {questions.map(({ id, pergunta, resposta }) => ( */}
                  {/*      <AccordionItem key={id}> */}
                  {/*        <AccordionItemHeading> */}
                  {/*          <AccordionItemButton> */}
                  {/*            {pergunta} */}
                  {/*          </AccordionItemButton> */}
                  {/*        </AccordionItemHeading> */}
                  {/*        <AccordionItemPanel> */}
                  {/*          <p>{resposta}</p> */}
                  {/*        </AccordionItemPanel> */}
                  {/*      </AccordionItem> */}
                  {/*    ))} */}
                  {/*  </Accordion> */}
                  {/* </div> */}
                </div>
              </section>
            </div>
            <div className='content about_options_bg'>
              <div className='container'>
                <div className='content__text'>
                  {topicsAboutDoctor.map(
                    ({ id, titulo, descricao, imagem }) => (
                      <About
                        key={id}
                        {...{
                          title: titulo,
                          description: descricao,
                          image: imagem.url
                        }}
                      />
                    )
                  )}
                </div>
              </div>
            </div>
          </section>
          <section className='internalPage'>
            <div className='container'>
              <section className='know_course'>
                <div>
                  <CenterTitle
                    {...{
                      text: 'Conheça',
                      title: 'Conheça o',
                      subtitle: 'Nosso curso'
                    }}
                  />
                  <p
                    className='content__text black'
                    data-aos='fade-up'
                    data-aos-offset='200'
                    data-aos-delay='150'
                    data-aos-duration='700'
                    data-aos-easing='ease-in-out'
                  >
                    {courseDescription}
                  </p>
                  <a href='./' className='more primary'>
                    Saiba mais
                  </a>
                </div>
                <div className='stripes_notebook'>
                  {/* link: courseLink */}
                  <img
                    src={ReplaceImageUrlService(notebook)}
                    alt='Notebook'
                    className='notebook'
                    data-aos='fade-up'
                    data-aos-offset='200'
                    data-aos-delay='50'
                    data-aos-duration='700'
                    data-aos-easing='ease-in-out'
                  />
                </div>
              </section>
            </div>
            <div className='container'>
              <div className='course_details'>
                {topicsAboutCourse.map(({ id, titulo, descricao }) => (
                  <BoxWeAre
                    key={id}
                    {...{
                      link: false,
                      title: titulo,
                      description: descricao
                    }}
                  />
                ))}
              </div>
            </div>
          </section>
          <Cases
            {...{
              ourCasesDescription: ourCasesText,
              ourCasesImages
            }}
          />
          {articles.length >= 1 && <ArticlesAndNews {...{ articles }} />}
          {/* <Instagram /> */}
        </>
      )}
    </main>
  );
}

export default AboutUs;
