import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import api from '../../services/api';

import Loading from '../../components/Loading';
import BannersPages from '../../components/Sliders/pages';
import CenterTitle from '../../components/Titles/center';
import BoxCourse from '../../components/Boxes/course';
import Partners from '../../components/Boxes/partners';
// import Instagram from '../../components/Boxes/instagram';

import bgMobile from '../../assets/images/bg_cursos_mobile.jpg';
import bg from '../../assets/images/bg_cursos.jpg';
// import curso from '../../assets/images/curso.jpg';

function Courses() {
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [backgroundText, setBackgroundText] = useState('');
  const [partners, setPartners] = useState([]);
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Promise.all([
      api.get('cursos?_sort=id:DESC'),
      api.get('homepage'),
      api.get('nossos-cursos')
    ]).then(([coursesData, partnersData, pageInfo]) => {
      setCourses(coursesData.data);
      setPartners(partnersData.data.parceiros);

      const { titulo, subtitulo, textoBackground, parceiros } = pageInfo.data;

      setTitle(titulo);
      setSubtitle(subtitulo);
      setBackgroundText(textoBackground);

      setLoading(false);
    });
  }, []);

  return (
    <main>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Helmet>
            <title>Cursos - {process.env.REACT_APP_TITLE}</title>
          </Helmet>
          <BannersPages
            {...{
              bgMobile,
              bg,
              text: backgroundText,
              title: subtitle,
              subtitle: title
            }}
          />
          <section className='internalPage clinica'>
            <span className='patternLeft' />
            <span className='patternRight' />
            <CenterTitle
              {...{
                text: 'Escolha',
                title: 'Escolha a',
                subtitle: 'Melhor opção'
              }}
            />
            <div className='container'>
              <div className='courses_list'>
                {courses.map(course => (
                  <BoxCourse
                    key={course.id}
                    {...{
                      link: true,
                      professor: course.doutor.nome,
                      type: course.presencial ? 'Presencial' : 'Online',
                      image: course.imagem.url,
                      title: course.titulo,
                      price: course.valorAVista,
                      description: course.descricao,
                      slug: course.slug
                    }}
                  />
                ))}
              </div>
            </div>
          </section>
          <section className='course_partners'>
            <Partners {...{ partners }} />
          </section>
          {/* <Instagram /> */}
        </>
      )}
    </main>
  );
}

export default Courses;
