import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';

import { Helmet } from 'react-helmet';
import BannersPages from '../../components/Sliders/pages';
import StrokeTitle from '../../components/Titles/stroke';
import CenterTitle from '../../components/Titles/center';
import About from '../../components/Boxes/about';
import Loading from '../../components/Loading';

import bgMobile from '../../assets/images/bg_home_experiencia_mobile.jpg';
import bg from '../../assets/images/bg_home_experiencia.jpg';
import play from '../../assets/images/play.png';
import icone02 from '../../assets/images/icone_servicos02.png';
import icone03 from '../../assets/images/icone_servicos03.png';
import api from '../../services/api';

function Expertise() {
  const [videoDescription, setVideoDescription] = useState('');
  const [videoLink, setVideoLink] = useState('');
  const [myFormation, setMyFormation] = useState('');
  const [myFormationText, setMyFormationText] = useState('');
  const [graduations, setGraduations] = useState([]);
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [description, setDescription] = useState('');
  const [topics, setTopics] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api.get('experiencia-e-formacao').then(({ data }) => {
      setVideoDescription(data.videoDescricao);
      setVideoLink(data.videoLink);
      setMyFormation(data.minhaFormacao);
      setMyFormationText(data.minhaFormacaoTexto);
      setGraduations(data.graduacoes);
      setTitle(data.titulo);
      setSubtitle(data.subtitulo);
      setDescription(data.descricao);
      setTopics(data.topicos);
      setLoading(false);
    });
  }, []);

  return (
    <main className='internal'>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Helmet>
            <title>
              Experiências & Formações - {process.env.REACT_APP_TITLE}
            </title>
          </Helmet>
          <BannersPages
            {...{
              bgMobile,
              bg,
              text: 'Experiencias',
              title: 'Experiências &',
              subtitle: 'Formações'
            }}
          />
          <section className='internalPage minPad'>
            <div className='container'>
              <div
                data-aos='fade-up'
                data-aos-offset='200'
                data-aos-delay='50'
                data-aos-duration='700'
                data-aos-easing='ease-in-out'
              >
                <StrokeTitle
                  {...{
                    divClass: 'black doctor',
                    letter: 'V',
                    letterClass: 'whiteLetter',
                    title: 'Vídeo',
                    subtitle: 'Dr. Thiago Tofaneto'
                  }}
                />
                <p
                  className='content__text black'
                  data-aos='fade-up'
                  data-aos-offset='200'
                  data-aos-delay='50'
                  data-aos-duration='700'
                  data-aos-easing='ease-in-out'
                >
                  {videoDescription}
                </p>
              </div>
              <div
                className='video'
                data-aos='fade-up'
                data-aos-offset='200'
                data-aos-delay='50'
                data-aos-duration='700'
                data-aos-easing='ease-in-out'
              >
                <div className='videoplayer'>
                  <ReactPlayer
                    className='react-player videoplayer'
                    url='https://www.youtube.com/watch?v=ysz5S6PUM-U'
                  />
                  {/* <img */}
                  {/*  src={play} */}
                  {/*  className='play' */}
                  {/*  alt='Assista o vídeo' */}
                  {/*  title='Assista o vídeo' */}
                  {/* /> */}
                </div>
              </div>
            </div>
          </section>
          <section className='internalPage extend expertise'>
            <div className='container'>
              <CenterTitle
                {...{
                  text: 'Formacao',
                  title: 'Minha',
                  subtitle: 'Formação'
                }}
              />
              <p
                className='content__text black text-center divider'
                data-aos='fade-up'
                data-aos-offset='200'
                data-aos-delay='50'
                data-aos-duration='700'
                data-aos-easing='ease-in-out'
              >
                {myFormation}
              </p>
              <p
                className='content__text black text-center subtext'
                data-aos='fade-up'
                data-aos-offset='200'
                data-aos-delay='50'
                data-aos-duration='700'
                data-aos-easing='ease-in-out'
              >
                {myFormationText}
              </p>
              <p
                className='content__text black text-center'
                data-aos='fade-up'
                data-aos-offset='200'
                data-aos-delay='50'
                data-aos-duration='700'
                data-aos-easing='ease-in-out'
              >
                <strong>Graduação e cursos</strong>
              </p>
              <div
                className='courses'
                data-aos='fade-up'
                data-aos-offset='200'
                data-aos-delay='50'
                data-aos-duration='700'
                data-aos-easing='ease-in-out'
              >
                {graduations.map(({ id, titulo, ano, descricao }) => (
                  <div key={id} className='courses__description'>
                    <strong>{`${titulo} - ${ano}`}</strong>
                    <p>{descricao}</p>
                  </div>
                ))}
              </div>
            </div>
          </section>
          <section className='about'>
            <div className='container'>
              <div className='content about_options'>
                <StrokeTitle
                  {...{
                    divClass: 'transparent',
                    letter: title.substring(0, 1),
                    title,
                    subtitle
                  }}
                />
                <p
                  className='content__text'
                  data-aos='fade-up'
                  data-aos-offset='200'
                  data-aos-delay='50'
                  data-aos-duration='700'
                  data-aos-easing='ease-in-out'
                >
                  {description}
                </p>
              </div>
            </div>
            <div className='container flexRow aboutTopics'>
              {topics.map(({ id, titulo, descricao, imagem }) => (
                <About
                  key={id}
                  {...{
                    title: titulo,
                    description: descricao,
                    image: imagem.url
                  }}
                />
              ))}
            </div>
          </section>
        </>
      )}
    </main>
  );
}

export default Expertise;
