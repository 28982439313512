import React, { useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import ReactMarkdown from 'react-markdown';
import { Picture } from 'react-responsive-picture';

import phone from '../../assets/images/phone-call.png';
import ReplaceImageUrlService from '../../services/ReplaceImageUrlService';

function BannersHome({
  sliders,
  facebook,
  instagram,
  youtube,
  email,
  telephone
}) {
  const handleDragStart = e => e.preventDefault();
  const [currentIndex, setCurrentIndex] = useState(0);

  const items = sliders.map(
    ({ id, titulo, subtitulo, descricao, imagem, imagemMobile }, index) => (
      <div
        key={id}
        className='slide'
        style={{
          backgroundImage: `url(${
            window.innerWidth <= process.env.REACT_APP_MOBILE.replace(/\D/g, '')
              ? imagemMobile.url
              : imagem.url
          })`
        }}
      >
        {/* <Picture */}
        {/*  onDragStart={handleDragStart} */}
        {/*  sources={[ */}
        {/*    { */}
        {/*      srcSet: imagemMobile.url, */}
        {/*      media: `(max-width: ${process.env.REACT_APP_MOBILE})` */}
        {/*    }, */}
        {/*    { */}
        {/*      srcSet: imagem.url */}
        {/*    } */}
        {/*    // { */}
        {/*    //   srcSet: "path-to-desktop-image.webp", */}
        {/*    //   type: "image/webp" */}
        {/*    // } */}
        {/*  ]} */}
        {/* /> */}
        <div className='slideText'>
          <span className='number'>
            /{index + 1 < 10 ? `0${index + 1}` : index}
          </span>
          <span
            className='call'
            data-aos='fade-up'
            data-aos-offset='200'
            data-aos-delay='100'
            data-aos-duration='700'
            data-aos-easing='ease-in-out'
          >
            {subtitulo}
          </span>
          <p
            className='title'
            data-aos='fade-up'
            data-aos-offset='200'
            data-aos-delay='150'
            data-aos-duration='700'
            data-aos-easing='ease-in-out'
          >
            <ReactMarkdown source={titulo} />
          </p>
          <p
            className='description'
            data-aos='fade-up'
            data-aos-offset='200'
            data-aos-delay='200'
            data-aos-duration='700'
            data-aos-easing='ease-in-out'
          >
            {descricao}
          </p>
        </div>
      </div>
    )
  );

  return (
    <div className='highlight'>
      <div className='bannersHome'>
        <AliceCarousel
          // autoPlay
          // autoPlayInterval={5000}
          items={items}
          animationType='fade'
          activeIndex={currentIndex}
          onSlideChanged={e => {
            setCurrentIndex(e.item);
          }}
          infinite
          disableButtonsControls
          disableDotsControls
        />
        <div className='thumbs'>
          {sliders.map(({ id, imagem }, index) => (
            <a
              href='#!'
              key={`thumbs${index}`}
              className={`thumbsImg ${currentIndex !== index || 'active'}`}
              onClick={() => {
                setCurrentIndex(index);
              }}
            >
              <img src={ReplaceImageUrlService(imagem.url)} alt='thumb' />
            </a>
          ))}
        </div>
        <div className='social'>
          <a href={facebook} rel='noreferrer' target='_blank'>
            Fb
          </a>
          <a href={instagram} rel='noreferrer' target='_blank'>
            In
          </a>
          <a href={youtube} rel='noreferrer' target='_blank'>
            Ytb
          </a>
        </div>
        <div className='contacts'>
          <a href={`tel:${telephone}`}>
            <img src={ReplaceImageUrlService(phone)} alt='Telefone' />
            {telephone}
          </a>
          <span>/</span>
          <a href='mailto:contato@tofainstituto.com.br'>{email}</a>
        </div>
      </div>
    </div>
  );
}

export default BannersHome;
