import React from 'react';
import { Link } from 'react-router-dom';

import { parseISO } from 'date-fns';

import AliceCarousel from 'react-alice-carousel';
import BoxNews from '../Boxes/news';

function SliderNews({ artigos }) {
  // const handleDragStart = e => e.preventDefault();

  const items =
    artigos &&
    artigos.map(artigo => (
      <div className='newsSlider'>
        <BoxNews
          {...{
            // image: 'http://localhost:3000/assets/images/tratamento.jpg',
            date: Intl.DateTimeFormat('pt-BR', {
              day: '2-digit',
              month: '2-digit'
            }).format(parseISO(artigo.created_at)),
            image: artigo.imagem.url,
            title: artigo.titulo,
            description: artigo.resumo
          }}
        />
        <Link to={`blog/${artigo.slug}`} className='more'>
          Saiba mais
        </Link>
      </div>
    ));
  return (
    <div
      className='container'
      data-aos='fade-up'
      data-aos-offset='200'
      data-aos-delay='50'
      data-aos-duration='700'
      data-aos-easing='ease-in-out'
    >
      <AliceCarousel
        autoPlayInterval={5000}
        items={items}
        animationType='fade'
        // autoPlay
        infinite
        disableDotsControls
        responsive={{
          0: {
            items: 1
          },
          1024: {
            items: 3
          }
        }}
      />
    </div>
  );
}

export default SliderNews;
