import { Helmet } from 'react-helmet';
import React, { useEffect, useState } from 'react';
import { mask, unMask } from 'remask';
import jwtDecode from 'jwt-decode';
import pagarme from 'pagarme';
import { toast } from 'react-toastify';
import { format, formatDistance, addDays, addMonths } from 'date-fns';

import BannersPages from '../../components/Sliders/pages';
import Loading from '../../components/Loading';

import bgMobile from '../../assets/images/bg_blog_mobile.jpg';
import bg from '../../assets/images/bg_pagamento.jpg';
import pagarMeLogo from '../../assets/images/pagarme.png';
import api from '../../services/api';
import ErrorMessageService from '../../services/ErrorMessageService';

function Cart({ match }) {
  const [courseData, setCourseData] = useState({});
  const [infos, setInfos] = useState({});
  const [insertBoletos, setInsertBoletos] = useState({});
  const [telefone, setTelefone] = useState('');
  const [celular, setCelular] = useState('');
  const [cpf, setCPF] = useState('');
  const [cep, setCep] = useState('');
  const [nascimento, setNascimento] = useState('');
  const [paymentOptions, setPaymentOptions] = useState('boleto');
  const [parcelas, setParcelas] = useState(1);
  const [loading, setLoading] = useState(true);
  const [logged, setLogged] = useState(false);

  async function setPaymentMethod(type) {
    setPaymentOptions(type);
  }

  async function destroySession() {
    sessionStorage.clear();
    setLogged(false);
  }

  async function loadInfos() {
    if (sessionStorage.getItem('userTofa') !== null) {
      const userValues = sessionStorage.getItem('userTofa');
      setInfos(jwtDecode(userValues));
      setLogged(true);
    }
  }

  useEffect(() => {
    api.get(`cursos?slug=${match.params.slug}`).then(({ data }) => {
      // console.log(data[0]);
      setCourseData(data[0]);
      loadInfos();
      setLoading(false);
    });
  }, []);

  function login(e) {
    e.preventDefault();

    const postData = {
      email: e.target.emailLogin.value,
      senha: e.target.passwordLogin.value
    };
    // eslint-disable-next-line
    // console.log(postData);
    api
      .post(`sessions`, postData)
      .then(({ data }) => {
        sessionStorage.setItem('userTofa', data.authorizationKey);
        setInfos(jwtDecode(data.authorizationKey));
        setLogged(true);
      })
      .catch(error => {
        // eslint-disable-next-line
        ErrorMessageService(error);
      });
  }

  function cadastroUsuario(e) {
    e.preventDefault();

    const postData = {
      nome: e.target.nome.value,
      cpf: e.target.cpf.value,
      dataNascimento: e.target.nascimento.value
        .replace(/\//g, '-')
        .split('-')
        .reverse()
        .join('-'),
      email: e.target.email.value,
      telefone: e.target.telefone.value,
      celular: e.target.celular.value,
      rua: e.target.endereco.value,
      numero: e.target.numero.value,
      bairro: e.target.bairro.value,
      complemento: e.target.complemento.value,
      cidade: e.target.cidade.value,
      estado: e.target.estado.value,
      cep: e.target.cep.value,
      cidadania: e.target.cidadania.value,
      senha: e.target.password.value
    };
    // console.log(postData);

    api
      .post(`usuarios`, postData)
      .then(() => {
        api
          .post(`sessions`, postData)
          .then(() => {
            setLogged(true);
          })
          .catch(error => {
            // eslint-disable-next-line
            ErrorMessageService(error);
          });
      })
      .catch(error => {
        // eslint-disable-next-line
        ErrorMessageService(error);
      });
  }

  async function pagarMe() {
    // NÃO APAGUEM ESTA LINHA (A chamada está sendo feita via script em public/index.html)
    // eslint-disable-next-line no-undef
    const checkout = new PagarMeCheckout.Checkout({
      encryption_key: process.env.REACT_APP_PAGARME,
      success(data) {
        api
          .get(
            `https://api.pagar.me/1/transactions/${data.token}?api_key=${process.env.REACT_APP_PAGARME_API}`
          )
          .then(transaction => {
            const tID = transaction.data.tid;
            api
              .post(`pedidos`, {
                type: 'card',
                data: {
                  curso: courseData.id,
                  valorPago: courseData.valorAVista,
                  idTransacao: `${tID}`
                },
                tofaauthorization: `Bearer ${sessionStorage.getItem(
                  'userTofa'
                )}`
              })
              .then(({ data: dataPedidoBoleto }) => {
                // eslint-disable-next-line
                console.log(dataPedidoBoleto);
                toast.success('Curso adquirido com sucesso!', {
                  position: toast.POSITION.TOP_RIGHT
                });
              })
              .catch(({ error }) => {
                // eslint-disable-next-line
                console.log(error);
                toast.error('Houve um problema com a sua requisição.', {
                  position: toast.POSITION.TOP_RIGHT
                });
              });
          });
      },
      error(err) {
        // eslint-disable-next-line
        console.log('error',JSON.stringify(err));
        toast.error('Houve um problema com a sua requisição.', {
          position: toast.POSITION.TOP_RIGHT
        });
      },
      close() {
        // eslint-disable-next-line
        console.log('The modal has been closed.');
      }
    });

    checkout.open({
      amount: courseData.valorAVista * 100, // valor em centavos
      buttonText: 'Pagar',
      capture: 'true',
      async: 'false',
      customerData: 'false',
      createToken: 'true',
      paymentMethods: 'credit_card',
      defaultInstallments: process.env.REACT_APP_PAGARME_PARCELAS_CARTAO,
      minInstallments: 1,
      freeInstallments: courseData.maximoParcelasSemJuros,
      interestRate: courseData.porcentagemDeJuros,
      maxInstallments: process.env.REACT_APP_PAGARME_PARCELAS_CARTAO,
      uiColor: '#b89050',
      customer: {
        external_id: infos.claims.id,
        name: infos.claims.nome,
        type: 'individual',
        country: 'br',
        email: infos.claims.email,
        documents: [
          {
            type: 'cpf',
            number: infos.claims.cpf
          }
        ],
        phone_numbers: [
          `+55${infos.claims.telefone.replace(/\D/g, '')}`,
          `+55${infos.claims.celular.replace(/\D/g, '')}`
        ],
        birthday: infos.claims.dataNascimento
      },
      billing: {
        name: infos.claims.nome,
        address: {
          country: 'br',
          state: infos.claims.estado,
          city: infos.claims.cidade,
          neighborhood: infos.claims.bairro,
          street: infos.claims.rua,
          street_number: infos.claims.numero,
          zipcode: infos.claims.cep.replace(/\D/g, '')
        }
      },
      shipping: {
        name: infos.claims.nome,
        fee: infos.claims.id,
        delivery_date: '2017-12-25',
        expedited: true,
        address: {
          country: 'br',
          state: infos.claims.estado,
          city: infos.claims.cidade,
          neighborhood: infos.claims.bairro,
          street: infos.claims.rua,
          street_number: infos.claims.numero,
          zipcode: infos.claims.cep.replace(/\D/g, '')
        }
      },
      items: [
        {
          id: courseData.id,
          title: courseData.titulo,
          unit_price: courseData.valorAVista * 100,
          quantity: 1,
          tangible: true
        }
      ]
    });
  }

  async function pagarMeBoleto() {
    // eslint-disable-next-line
    console.log(parcelas)
    let valorBoletoTotal;
    let valorBoleto;
    const dataVenc = addDays(new Date(), 2);
    switch (parcelas) {
      case '2':
        valorBoletoTotal = courseData.valorBoleto2x;
        valorBoleto = Math.ceil((courseData.valorBoleto2x / parcelas) * 100);
        break;
      case '3':
        valorBoletoTotal = courseData.valorBoleto3x;
        valorBoleto = Math.ceil((courseData.valorBoleto3x / parcelas) * 100);
        break;
      case '4':
        valorBoletoTotal = courseData.valorBoleto4x;
        valorBoleto = Math.ceil((courseData.valorBoleto4x / parcelas) * 100);
        break;
      default:
        valorBoletoTotal = courseData.valorAVista;
        valorBoleto = courseData.valorAVista * 100;
    }

    const parcelasBoleto = [];
    pagarme.client
      .connect({ api_key: process.env.REACT_APP_PAGARME_API })
      .then(client => {
        for (let i = 1; i <= parcelas; i += 1) {
          // console.log(format(addMonths(dataVenc, i - 1), 'yyyy-MM-dd'));
          // console.log(valorBoleto);
          client.transactions
            .create({
              async: 'false', // necessario para que retorne a url do boleto
              amount: valorBoleto,
              capture: 'true',
              // customerData: 'false',
              boleto_instructions: courseData.descricaoFatura,
              boleto_expiration_date: format(
                addMonths(dataVenc, i - 1),
                'yyyy-MM-dd'
              ),
              payment_method: 'boleto',
              postback_url:
                'https://app.tofainstituto.com.br/change-transaction-status',
              customer: {
                type: 'individual',
                country: 'br',
                name: infos.claims.nome,
                documents: [
                  {
                    type: 'cpf',
                    number: infos.claims.cpf
                  }
                ]
              }
            })
            .then(retorno => {
              parcelasBoleto.push({
                linkBoleto: retorno.boleto_url,
                valor: retorno.amount / 100,
                idTransacao: `${retorno.id}`,
                dataVencimento: format(
                  new Date(retorno.boleto_expiration_date),
                  'yyyy-MM-dd'
                )
              });

              if (parcelasBoleto.length === +parcelas) {
                api
                  .post(`pedidos`, {
                    type: 'boleto',
                    data: {
                      pedido: {
                        curso: courseData.id,
                        valorPago: valorBoletoTotal
                      },
                      parcelasBoleto
                    },
                    tofaauthorization: `Bearer ${sessionStorage.getItem(
                      'userTofa'
                    )}`
                  })
                  .then(({ data: dataPedidoBoleto }) => {
                    // eslint-disable-next-line
                    console.log(dataPedidoBoleto)
                    toast.success(dataPedidoBoleto.message, {
                      position: toast.POSITION.TOP_RIGHT
                    });
                  })
                  .catch(error => {
                    // eslint-disable-next-line
                    console.log(error.response);
                    toast.error(
                      `Houve um problema com a sua requisição (${error.response.message}).`,
                      {
                        position: toast.POSITION.TOP_RIGHT
                      }
                    );
                  });
              }
            });
        }
      })
      .catch(error => {
        // eslint-disable-next-line
        console.log(error);
        toast.error('Houve um problema com a sua requisição.', {
          position: toast.POSITION.TOP_RIGHT
        });
      });

    // console.log({
    //   type: 'boleto',
    //   data: {
    //     pedido: {
    //       curso: courseData.id,
    //       valorPago: valorBoletoTotal
    //     },
    //     parcelasBoleto
    //   },
    //   tofaauthorization: `Bearer ${sessionStorage.getItem('userTofa')}`
    // });
    // return false;
  }

  return (
    <main>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Helmet>
            <title>
              {courseData.bannerTitulo} {courseData.bannerSubtitulo} | Pagamento
              - {process.env.REACT_APP_TITLE}
            </title>
          </Helmet>
          <BannersPages
            {...{
              bgMobile,
              bg,
              text: 'Pagamento',
              title: 'Pagamento',
              subtitle: ''
            }}
          />
          <section className='internalPage carrinho'>
            <div className='container pagamento'>
              <div>
                <form
                  action=''
                  className={`contact_services ${!logged || 'hide'}`}
                  onSubmit={login}
                >
                  <div className='cartSteps'>
                    <strong>Já é cadastrado?</strong>
                    <p>Realize seu login</p>
                    <div className='baseForm'>
                      <div className='control'>
                        <label htmlFor='emailLogin'>
                          <input
                            type='email'
                            name='emailLogin'
                            id='emailLogin'
                            placeholder='E-mail'
                          />
                        </label>
                      </div>
                      <div className='control'>
                        <label htmlFor='passwordLogin'>
                          <input
                            type='password'
                            name='passwordLogin'
                            id='passwordLogin'
                            placeholder='Senha'
                          />
                        </label>
                      </div>
                      <div className='control'>
                        <input type='submit' value='Logar' />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div>
                <form
                  action=''
                  className={`contact_services ${!logged || 'hide'}`}
                  onSubmit={cadastroUsuario}
                >
                  <div className='cartSteps'>
                    <strong>Faça seu cadastro</strong>
                    <p>É rapidinho!</p>
                    <div className='baseForm'>
                      <div className='control'>
                        <label htmlFor='nome'>
                          <input
                            type='text'
                            name='nome'
                            id='nome'
                            placeholder='Nome'
                          />
                        </label>
                      </div>
                      <div className='control'>
                        <label htmlFor='email'>
                          <input
                            type='email'
                            name='email'
                            id='email'
                            placeholder='E-mail'
                          />
                        </label>
                      </div>
                      <div className='control'>
                        <label htmlFor='cpf'>
                          <input
                            type='text'
                            name='cpf'
                            id='cpf'
                            placeholder='CPF'
                            value={cpf}
                            onChange={({ target }) => {
                              setCPF(
                                mask(unMask(target.value), ['999.999.999-99'])
                              );
                            }}
                          />
                        </label>
                      </div>
                      <div className='control'>
                        <label htmlFor='nascimento'>
                          <input
                            type='text'
                            name='nascimento'
                            id='nascimento'
                            placeholder='Data de nascimento'
                            value={nascimento}
                            onChange={({ target }) => {
                              setNascimento(
                                mask(unMask(target.value), ['99/99/9999'])
                              );
                            }}
                          />
                        </label>
                      </div>
                      <div className='control'>
                        <label htmlFor='telefone'>
                          <input
                            type='tel'
                            name='telefone'
                            id='telefone'
                            placeholder='Telefone'
                            value={telefone}
                            onChange={({ target }) => {
                              setTelefone(
                                mask(unMask(target.value), ['(99) 9999-9999'])
                              );
                            }}
                          />
                        </label>
                      </div>
                      <div className='control'>
                        <label htmlFor='celular'>
                          <input
                            type='tel'
                            name='celular'
                            id='celular'
                            placeholder='Celular'
                            value={celular}
                            onChange={({ target }) => {
                              setCelular(
                                mask(unMask(target.value), ['(99) 99999-9999'])
                              );
                            }}
                          />
                        </label>
                      </div>
                      <div className='control'>
                        <label htmlFor='endereco'>
                          <input
                            type='text'
                            name='endereco'
                            id='endereco'
                            placeholder='Endereço'
                          />
                        </label>
                      </div>
                      <div className='control'>
                        <label htmlFor='numero'>
                          <input
                            type='text'
                            name='numero'
                            id='numero'
                            placeholder='Número'
                          />
                        </label>
                      </div>
                      <div className='control'>
                        <label htmlFor='complemento'>
                          <input
                            type='text'
                            name='complemento'
                            id='complemento'
                            placeholder='Complemento'
                          />
                        </label>
                      </div>
                      <div className='control'>
                        <label htmlFor='bairro'>
                          <input
                            type='text'
                            name='bairro'
                            id='bairro'
                            placeholder='Bairro'
                          />
                        </label>
                      </div>
                      <div className='control'>
                        <label htmlFor='cidade'>
                          <input
                            type='text'
                            name='cidade'
                            id='cidade'
                            placeholder='Cidade'
                          />
                        </label>
                      </div>
                      <div className='control'>
                        <label htmlFor='estado'>
                          <input
                            type='text'
                            name='estado'
                            id='estado'
                            placeholder='Estado'
                            maxLength={2}
                          />
                        </label>
                      </div>
                      <div className='control'>
                        <label htmlFor='cep'>
                          <input
                            type='text'
                            name='cep'
                            id='cep'
                            placeholder='CEP'
                            value={cep}
                            onChange={({ target }) => {
                              setCep(
                                mask(unMask(target.value), ['99.999-999'])
                              );
                            }}
                          />
                        </label>
                      </div>
                      <div className='control'>
                        <label htmlFor='password'>
                          <input
                            type='password'
                            name='password'
                            id='password'
                            placeholder='Senha'
                          />
                        </label>
                      </div>
                      <div className='control'>
                        <input
                          type='hidden'
                          name='cidadania'
                          id='cidadania'
                          value='br'
                        />
                        <input type='submit' value='Cadastrar' />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <form action='' className={`contact_services ${logged || 'hide'}`}>
              <div className='box_logout'>
                <a
                  href='#!'
                  className='logout'
                  onClick={() => {
                    destroySession();
                  }}
                >
                  SAIR
                </a>
              </div>
              <div className='container pagamento'>
                <div>
                  <div className='cartSteps'>
                    <strong>
                      <span className='number'>01</span>Informações do Curso
                    </strong>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <strong>Nome:</strong>
                          </td>
                          <td>
                            {courseData.bannerSubtitulo}{' '}
                            {courseData.bannerTitulo}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Valor:</strong>
                          </td>
                          <td>
                            {courseData &&
                              courseData.valorAVista.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL'
                              })}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Modelo:</strong>
                          </td>
                          <td>
                            <strong>
                              <span>
                                {courseData.presencial
                                  ? 'Presencial'
                                  : 'Online'}
                              </span>
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div>
                  <div className='cartSteps'>
                    <strong>
                      <span className='number'>02</span>Formas de pagamento
                    </strong>
                  </div>
                  <div className='paymentOptions'>
                    <label htmlFor='boleto'>
                      <input
                        type='radio'
                        name='payment'
                        value='boleto'
                        id='boleto'
                        checked={paymentOptions === 'boleto' || false}
                        onChange={() => {
                          setPaymentMethod('boleto');
                        }}
                      />
                      Boleto
                    </label>
                    <label htmlFor='credito'>
                      <input
                        type='radio'
                        name='payment'
                        value='credito'
                        id='credito'
                        // checked={setPaymentMethod('credito')}
                        onChange={() => {
                          setPaymentMethod('credito');
                        }}
                      />
                      Crédito
                    </label>
                  </div>
                  <div className='cardForm'>
                    <div className='cardForm_interna'>
                      <div
                        className={`pagarme control ${
                          paymentOptions !== 'boleto' || 'hide'
                        }`}
                      >
                        <button
                          type='button'
                          onClick={() => {
                            pagarMe();
                          }}
                        >
                          <span>Pagamento via</span>
                          <img src={pagarMeLogo} alt='Pagar.me' />
                        </button>
                      </div>
                    </div>
                    <div
                      className={`control ${
                        paymentOptions !== 'credito' || 'hide'
                      }`}
                    >
                      <label htmlFor='parcelas'>
                        <span>
                          Quantidade de parcelas (máx.{' '}
                          {process.env.REACT_APP_PAGARME_PARCELAS_BOLETO}x)
                        </span>
                        <input
                          type='number'
                          min={1}
                          max={4}
                          defaultValue={1}
                          name='parcelas'
                          id='parcelas'
                          placeholder='Parcelamento'
                          onChange={e => {
                            setParcelas(e.target.value);
                          }}
                        />
                      </label>
                    </div>
                    <div
                      className={`pagarme control ${
                        paymentOptions !== 'credito' || 'hide'
                      } ${
                        parcelas <=
                          process.env.REACT_APP_PAGARME_PARCELAS_BOLETO ||
                        'hide'
                      }`}
                    >
                      <button
                        type='button'
                        onClick={() => {
                          pagarMeBoleto();
                        }}
                      >
                        <span>Pagamento via</span>
                        <img src={pagarMeLogo} alt='Pagar.me' />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>
        </>
      )}
    </main>
  );
}

export default Cart;
