import Podcasts from '../Sliders/podcasts';

import search from '../../assets/images/search_blog.png';
import ReplaceImageUrlService from '../../services/ReplaceImageUrlService';

function Blog() {
  const arrayPages = ['podcasts'];
  const page = document.location.pathname.replace('/', '');

  return (
    <section
      className={`content ${arrayPages.includes(page) ? 'podcasts' : ''}`}
    >
      <div
        className='container'
        data-aos='fade-up'
        data-aos-offset='200'
        data-aos-delay='50'
        data-aos-duration='700'
        data-aos-easing='ease-in-out'
      >
        <div className='filter'>
          <div className='filter_options'>
            <a href='/blog'>Blog</a>
            <a href='/podcasts' className='active'>
              Podcast
            </a>
          </div>
          <form action='' className='search'>
            <div className='baseForm'>
              <div className='control'>
                <label htmlFor='search'>
                  <input type='text' name='search' id='search' />
                </label>
              </div>
              <div className='control'>
                {/* <input type='submit' value='Assinar' /> */}
                <button type='submit'>
                  <img src={ReplaceImageUrlService(search)} alt='Pesquisar' title='Pesquisar' />
                </button>
              </div>
            </div>
          </form>
        </div>
        <Podcasts />
      </div>
    </section>
  );
}

export default Blog;
