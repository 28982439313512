import StrokeTitle from '../Titles/stroke';
import imgCase from '../../assets/images/cases.jpg';
import ReplaceImageUrlService from '../../services/ReplaceImageUrlService';

function Cases({ ourCasesDescription, ourCasesImages }) {
  return (
    <section className='content cases'>
      <div className='container flexRow'>
        <div>
          <StrokeTitle
            {...{
              divClass: 'compressText',
              letter: 'N',
              title: 'Nossos',
              subtitle: 'Cases'
            }}
          />
          <p className='content__text'>
            {ourCasesDescription}
            <span>Cases</span>
          </p>
        </div>
        {/* {ourCasesImages.map(image => (
          <img src={image.url} alt='img' />
        ))} */}
        <div className='cases_images'>
          <div>
            <img
              src={ReplaceImageUrlService(ourCasesImages[0] ? ourCasesImages[0].url : imgCase)}
              alt='Case 01'
            />
            <img
              src={ReplaceImageUrlService(ourCasesImages[1] ? ourCasesImages[1].url : imgCase)}
              alt='Case 02'
            />
          </div>
          <div>
            <img
              src={ReplaceImageUrlService(ourCasesImages[2] ? ourCasesImages[2].url : imgCase)}
              alt='Case 03'
            />
            <img
              src={ReplaceImageUrlService(ourCasesImages[3] ? ourCasesImages[3].url : imgCase)}
              alt='Case 04'
            />
            <img
              src={ReplaceImageUrlService(ourCasesImages[4] ? ourCasesImages[4].url : imgCase)}
              alt='Case 05'
            />
          </div>
          <div>
            <img
              src={ReplaceImageUrlService(ourCasesImages[5] ? ourCasesImages[5].url : imgCase)}
              alt='Case 06'
            />
            <img
              src={ReplaceImageUrlService(ourCasesImages[6] ? ourCasesImages[6].url : imgCase)}
              alt='Case 07'
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Cases;
